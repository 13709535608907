import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../services/AuthProvider";
import LayoutPage from "../../layouts/base"
import { InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import React, { useEffect, useRef, useState } from "react";
import { faBuilding, faRemove } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from "../../../constants/ApiConfig";
import { useNavigate, useParams } from "react-router-dom";
import { Chart } from "chart.js";
import * as XLSX from 'xlsx';
import { generateRandomColors } from "../../../utils/colorUtils";

function ReportByBlockPage() {

    const itemsPerPage = 10;
    const navigate = useNavigate();

    const { user, hasPermission } = useAuth();
    const canManageReport = hasPermission('manage-report');

    const { divisionId } = useParams();

    const [searchTerm, setSearchTerm] = useState('');

    const clearSearch = () => {
        setSearchTerm('');
    };

    const chartBlockCategory = useRef([]);
    const [divisionData, setDivisionData] = useState([]);
    const [blockData, setBlockData] = useState([]);

    useEffect(() => {
        const fetchBlockData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}report/block/${divisionId}`);
                if (response.ok) {
                    const data = await response.json();
                    console.log(data)
                    setDivisionData(data.division);
                    setBlockData(data.blocks);

                    chartBlockCategory.current = new Array(data.blocks.length).fill().map(() => React.createRef());
                    // Populate chartRefs with refs for each block
                    const newChartRefs = {};
                    data.blocks.forEach(block => {
                        newChartRefs[block.id] = React.createRef();
                    });
                    chartBlockCategory.current = newChartRefs;
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchBlockData();
    }, []);

    const filteredBlocks = blockData.filter(block =>
        block.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredBlocks.slice(indexOfFirstItem, indexOfLastItem);

    const renderIndex = (index) => {
        return (currentPage - 1) * itemsPerPage + index + 1;
    };

    const totalPages = Math.ceil(filteredBlocks.length / itemsPerPage);
    const maxPagesToShow = 5;

    // Change page
    const paginate = (pageNumber) => {
        if (pageNumber < 1 || pageNumber > totalPages) {
            return;
        }
        setCurrentPage(pageNumber);
    };

    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (endPage - startPage + 1 < maxPagesToShow) {
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    const totalEntries = filteredBlocks.length;
    const startEntry = renderIndex(0);
    const endEntry = renderIndex(currentItems.length - 1);

    // Function to generate an array of page numbers to display
    const getPageNumbers = (start, end) => {
        return Array.from({ length: end - start + 1 }, (_, index) => start + index);
    };

    // Function to export data to Excel
    const exportToExcel = () => {
        console.log(filteredBlocks);
        // Include index numbering and required columns in the data
        const dataWithIndex = filteredBlocks.map((block, index) => ({
            '#': index + 1,
            'DIVISION': block.division_name || '-',
            'BLOCK': block.name || '-',
            'TOTAL SUB-BLOCKS': block.total_subBlocks || '0',
            'TOTAL PLANTS': block.plantations_count || '0',
        }));
    
        // Convert data to worksheet
        const ws = XLSX.utils.json_to_sheet(dataWithIndex, {
            header: ['#', 'DIVISION', 'BLOCK', 'TOTAL SUB-BLOCKS', 'TOTAL PLANTS'], // Define the header column titles
        });

        ws['!cols'] = [{ wch: 5 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }];

        // Generate Excel file
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Blocks");
        XLSX.writeFile(wb, "report_by_block.xlsx");
    };  

    // Statistics of type chart
    useEffect(() => {
        let newChartCategory;

        const fetchCategoryData = async (blockId, index) => {
            try {

                const url = new URL(`${API_BASE_URL}report/block/${divisionId}`);
                url.searchParams.append('blockId', blockId);

                const response = await fetch(url);
                const data = await response.json();

                const statistics = data.statistics;
                console.log(statistics)

                const labels = statistics.map(item => item.name);  console.log(labels)
                const values = statistics.map(item => item.total_plantations); console.log(values)

                const backgroundColors = generateRandomColors(labels.length);

                const chartData = {
                    labels,
                    datasets: [{
                        data: values,
                        backgroundColor: backgroundColors,
                    }],
                };

                // Configuration options for the pie chart
                const options = {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true,
                        },
                        tooltip: {
                            callbacks: {
                                label: (context) => {
                                    const label = context.label || 'Others';
                                    const value = context.formattedValue || '';
                                    const percentage = context.dataset.data[context.dataIndex] / context.dataset.data.reduce((a, b) => a + b, 0) * 100;
                                    
                                    return `${value} Plant`;
                                },
                            },
                        },
                    },
                };

                // Get the context of the canvas element
                const canvas = chartBlockCategory.current[blockId]?.current; console.log(canvas)
                if (canvas) {
                    const ctx = canvas.getContext('2d');

                    // Continue with chart creation=
                    if (chartBlockCategory[blockId]?.current) {
                        chartBlockCategory[blockId]?.current.destroy();
                    }

                    // // Create the pie chart
                    newChartCategory = new Chart(ctx, {
                        type: 'pie',
                        data: chartData,
                        options,
                    });
                } else {
                    console.error("Canvas element not found or not mounted.");
                }
                                
                

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        blockData.forEach((block, index) => {
            fetchCategoryData(block.id, index);
        });

        return async () => {
            if (newChartCategory) {
                await newChartCategory.destroy();
            }
        };
    }, [blockData]);

    return (
        <LayoutPage>
            <div className="children">
                <div className="d-flex listTopBar top_bar p-3 bold_poppins justify-content-between">
                    <h4 className="mt-3 pg_title">
                        REPORT BY BLOCK ({divisionData.name})
                    </h4>
                </div>
                <div className="d-flex justify-content-between p-3">
                    <div>
                        <InputGroup>
                            <Form.Control
                                placeholder="Search..."
                                aria-label=""
                                aria-describedby="basic-addon1"
                                style={{ width: "200px" }}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                value={searchTerm}
                            />
                            {searchTerm && (
                                <InputGroup.Text id="clear-search" onClick={clearSearch}>
                                    <FontAwesomeIcon icon={faRemove} className="icon edit-icon" />
                                </InputGroup.Text>
                            )}
                        </InputGroup>
                    </div>
                    <div className='d-flex text-right'>
                        <button className='eksport_btn ml-2' onClick={exportToExcel}>
                            Export
                        </button>
                    </div>
                </div>
                <div className='m-3 table-container table_responsive'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Block</th>
                                <th className="text-center">Total Sub-Block</th>
                                <th className="text-center">Total Plant</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.length > 0 ? (
                                currentItems.map((block, index) => (
                                    <tr key={index}>
                                        <td>{renderIndex(index)}</td>
                                        <td>Block {block.name || '-'}</td>
                                        <td className="text-center">{block.total_subBlocks || '0'}</td>
                                        <td className="text-center">{block.plantations_count || '0'}</td>
                                        {(canManageReport) && <td className='text-center'>
                                            <div className="unwrap editdelete_icon">
                                                {canManageReport &&
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Sub-Block</Tooltip>} >
                                                        <FontAwesomeIcon
                                                            icon={faBuilding}
                                                            className="icon edit-icon"
                                                            onClick={() => navigate(`/report/division/block/sub-block/${block.id}`)}
                                                        /></OverlayTrigger>}
                                            </div>
                                        </td>}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="text-center">No data found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="d-flex flex-wrap-reverse">
                    <div className='col-md-6'>
                        <p className='pl-3' style={{ fontFamily: 'Poppins' }}>
                            Showing {startEntry} to {endEntry} of {totalEntries} entries
                        </p>
                    </div>

                    {/* Pagination component */}
                    <div className='col-md-6 pagination flex-wrap'>
                        <div>
                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                <button onClick={() => paginate(currentPage - 1)} className="page-link">
                                    Previous
                                </button>
                            </li>
                        </div>
                        <div className='d-flex flex-wrap pr-1'>
                            {getPageNumbers(startPage, endPage).map((page) => (
                                <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                                    <button onClick={() => paginate(page)} className="page-link">
                                        {page}
                                    </button>
                                </li>
                            ))}
                        </div>
                        <div >
                            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                <button onClick={() => paginate(currentPage + 1)} className="page-link">
                                    Next
                                </button>
                            </li>
                        </div>
                    </div>
                </div>
                <div className="row pt-3 px-4 pb-5">
                    <div className='card w-100'>
                        <div className='card-body'>
                            <h5 className='card-title'>Clones Overview By Block</h5>
                            <div className="my-4 row">
                                {blockData.map((block, index) => (
                                    <div key={index} className="col-lg-6 col-md-6 col-12 my-2">
                                        <h5 style={{ fontFamily: 'Poppins_bold' }}>Block {block.name}</h5>
                                        <div className="">
                                            <div key={block.id}><canvas ref={chartBlockCategory.current[block.id]}></canvas></div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutPage>
    )
}

export default ReportByBlockPage