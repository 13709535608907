export const setAuthData = (token, userId) => {
    localStorage.setItem('auth_token', token);
    localStorage.setItem('user_id', userId);
};

export const isAuthenticated = () => {
    const TOKEN_KEY = 'auth_token';
    const token = localStorage.getItem(TOKEN_KEY);
    return !!token; // Returns true if token exists, indicating the user is authenticated
};

export const logout = () => {
    const TOKEN_KEY = 'auth_token';
    localStorage.removeItem(TOKEN_KEY);
};

