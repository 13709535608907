import { createContext, useContext, useEffect, useState } from "react";
import { API_BASE_URL } from "../constants/ApiConfig";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    const USER_ID_KEY = 'user_id';
    const userId = localStorage.getItem(USER_ID_KEY);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}user/getUser/${userId}`);
                if (response.ok) {
                    const data = await response.json();
                    const user = data.user;
                    setUser(user);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, [userId]);


    const hasPermission = (permission) => {
        return user && user.role && user.role.permissions && user.role.permissions.some(p => p.name === permission);
    };

    return (
        <AuthContext.Provider value={{ user, hasPermission }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
export const useAuth = () => useContext(AuthContext);