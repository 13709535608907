import "./popup.css";
const PopupContent = ({ feature, map, overlay, selected }) => {
  let attributes = {};
  let keys = [
    // "geometry",
    "NOMBOR POK",
    "KLON",
    "TAHUN DITA",
    "NO BLOK",
    "BLOK",
    "DIV",
    "STATUS",
    // "LAT",
    // "LONG",
  ];
  if (feature) {
    attributes = feature.getProperties();
  }
  const closePopup = () => {
    overlay.setPosition(undefined);
    selected.getFeatures().clear();
    return false;
  };
  return (
    <>
      <div id="popupContainer">
        <a
          id="popup-closer"
          className="ol-popup-closer"
          onClick={closePopup}
        ></a>
        <div id="popup-content">
          <table className="myTable">
            <tbody>
              {keys.map((key, index) => (
                <tr key={index}>
                  <td>{key}</td>
                  <td>{attributes[key]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default PopupContent;
