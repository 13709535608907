import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../services/AuthProvider";
import LayoutPage from "../layouts/base"
import { faPencil, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useEffect, useState } from 'react';
import { API_BASE_URL } from "../../constants/ApiConfig";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import SuccessModal from "../components/success-modal";

function ProgramCalendarPage() {

    const { user, hasPermission } = useAuth();

    const canManageProgram = hasPermission('manage-program');
    const canCreateProgram = hasPermission('create-program');
    const canEditProgram = hasPermission('edit-program');
    const canDeleteProgram = hasPermission('delete-program');

    const itemsPerPage = 10;

    const [eventData, setEventData] = useState([]);
    const [allEventData, setAllEventData] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dateRangeInputs, setDateRangeInputs] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
    ]);

    const handleProgramModal = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);
    const [showModal, setShowModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [editEventId, setEditEventId] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showSuccessDeleteModal, setshowSuccessDeleteModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [editEventData, setEditEventData] = useState([]);
    const [showViewModal, setShowViewModal] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        description: '',
        startDate: '',
        endDate: '',
        color: '',
    });

    const handleSearchEvent = () => {
        const today = new Date();

        setStartDate(today);
        setEndDate(today);
        
        const dateRanges = [
            {
                startDate: today,
                endDate: today,
                key: 'selection',
            }
        ];
        
        setDateRangeInputs(dateRanges);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
            userId: user.id,
        });
    };

    const handleSubmitProgram = async (e) => {
        e.preventDefault();
        console.log(formData);

        try {
            const response = await fetch(`${API_BASE_URL}program/store`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.status == 200) {
                setShowModal(false);
                setShowSuccessModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        const fetchEventData = async () => {
            try {
                let url = `${API_BASE_URL}program/index`;
                if (startDate && endDate) {
                    let start_date =  startDate.toLocaleDateString('en-GB');
                    let end_date =  endDate.toLocaleDateString('en-GB');

                    url += `?startDate=${start_date}&endDate=${end_date}`;
                }
                const response = await fetch(url);
                if (response.ok) {
                    const data = await response.json();
                    setEventData(data.programs);
                    
                    // Get all event data
                    setAllEventData(data.all_programs);
                }
            } catch (error) {
                console.error('Error fetching event data:', error);
            }
        };

        fetchEventData();
    }, [startDate, endDate]);

    useEffect(() => {
        if(startDate && endDate) {
            const dateRanges = [
                {
                    startDate: startDate,
                    endDate: endDate,
                    key: 'selection',
                }
            ];
            
            setDateRangeInputs(dateRanges);
        }
    }, [startDate, endDate])
    
    const handleDateChange = (ranges) => {
        const { selection } = ranges;

        setStartDate(selection.startDate);
        setEndDate(selection.endDate);

        console.log(startDate);
        console.log(endDate);

        setDateRangeInputs([selection]);
    };

    // Edit code
    const handleEditEventModal = (eventId) => {
        setEditEventId(eventId);
        setShowEditModal(true);
    };

    const fetchEventById = async (id) => {
        try {
            const response = await fetch(`${API_BASE_URL}program/edit/${id}`);
            if (response.ok) {
                const data = await response.json();
                console.log(data)
                const program = data.program;
                setEditEventData(program);
            }
        } catch (error) {
            console.error('Error fetching block:', error);
        }
    };

    useEffect(() => {
        if (editEventId !== null) {
            fetchEventById(editEventId);
        }
    }, [editEventId]);

    useEffect(() => {
        if(editEventData && Object.keys(editEventData).length > 0) {
            const startDate = editEventData.start_date ? new Date(editEventData.start_date).toISOString().split('T')[0] : '';
            const endDate = editEventData.end_date ? new Date(editEventData.end_date).toISOString().split('T')[0] : '';

            setFormData({
                name: editEventData.name || '',
                description: editEventData.description || '',
                startDate: startDate || '',
                endDate: endDate || '',
                color: editEventData.color_code || '',
            });
        }
      }, [editEventData]);

    // Show code
    const handleEventClick = (eventId) => {
        setEditEventId(eventId);
        setShowViewModal(true);
    };

    //   Delete code
    const handleDeleteEvent = async (index) => {
        try {
            const response = await fetch(`${API_BASE_URL}program/destroy/${index}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status == 200) {
                setShowDeleteModal(false);
                setshowSuccessDeleteModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    };

    const handleSubmitEditEvent = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_BASE_URL}program/update/${editEventId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setEditEventId('');
                setShowEditModal(false);
                setShowSuccessModal(true);
            }
        } catch (error) {
            // Handle fetch errors
            console.error('Error updating data:', error);
        }
    }

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setDeleteIndex(null);
    };

    const handleConfirmDelete = (id) => {
        setDeleteIndex(id);
        setShowDeleteModal(true);
    };

    const closeSuccessModal = () => {
        setShowSuccessModal(false);
        window.location.reload();
    };

    const handleEditModalClose = () => {
        setShowEditModal(false);
    };

    const handleViewModalClose = () => {
        setShowViewModal(false);
    };

    return (
        <LayoutPage>
            <div className="d-flex listTopBar top_bar p-3 bold_poppins justify-content-between">
                <h4 className="mt-3 pg_title">
                    PROGRAMS
                </h4>
            </div>
            <div className='children'>
                {canCreateProgram &&
                    <div className="addDisease d-flex justify-content-center mb-4" onClick={handleProgramModal}>
                        <div className="icon">
                            <FontAwesomeIcon className="icon" icon={faPlus} />
                        </div>
                        <div className="text ml-3">
                            Program
                        </div>
                    </div>
                }
                <div className="row">
                    <div className='col-md-12 mb-3'>
                        <div className='card w-100'>
                            <div className='card-body'>
                                <h3 className='card-title mb-5'>Calendar</h3>
                                <div className='row'>
                                    <div className='d-flex flex-wrap ml-4'>
                                        <div className='mr-5 mb-3 '>
                                            <label className='label-date mr-3'>Start Date:</label>
                                            <DatePicker
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                                selectsStart
                                                startDate={startDate}
                                                endDate={endDate}
                                                className='form-control'
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                        <div className='mr-5 mb-3'>
                                            <label className='label-date mr-3'>End Date :</label>
                                            <DatePicker
                                                selected={endDate}
                                                onChange={(date) => setEndDate(date)}
                                                selectsEnd
                                                startDate={startDate}
                                                endDate={endDate}
                                                minDate={startDate}
                                                className='form-control'
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                        <div className='text-right'>
                                            <button onClick={handleSearchEvent} className='formResetBtn'>Reset</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='date-range-container'>
                                    <style>
                                        {`.date-range-container .rdrDateDisplayWrapper { display: none; }`}
                                    </style>
                                    <DateRangePicker
                                        onChange={handleDateChange}
                                        moveRangeOnFirstSelection={false}
                                        ranges={dateRangeInputs}
                                        className='programCalendar'
                                        style={{ width: '350%', height: '450px', fontSize: '20px' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='card w-100 my-4'>
                            <div className='card-body'>
                                <h3 className='card-title'>
                                    Events List&nbsp;
                                    {startDate && endDate ? (
                                        startDate.toDateString() !== new Date().toDateString() || endDate.toDateString() !== new Date().toDateString() ? 
                                        `(${startDate.toLocaleDateString('en-GB')} - ${endDate.toLocaleDateString('en-GB')})` :
                                        "(Today)"
                                    ) : (
                                        "(Today)"
                                    )}
                                </h3>
                                <div className="pt-3">
                                    <ul className="event-list">
                                        {eventData.length === 0 ? (
                                            <p className="label-date ">No events found</p>
                                        ) : (
                                            <ul className="event-list">
                                                {eventData.map((event, index) => (
                                                    <li key={index} className="event-item">
                                                        <div onClick={() => handleEventClick(event.id)} className="clickable-event">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <div className="event-name">{event.name}</div>
                                                                    <div className="event-dates">
                                                                        {`${event.start_date_formatted} - ${event.end_date_formatted}`}
                                                                    </div>
                                                                </div>
                                                                {(canManageProgram || canEditProgram || canDeleteProgram) && <div className="event-actions">
                                                                    {canEditProgram &&
                                                                        <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>} >
                                                                        <FontAwesomeIcon
                                                                            icon={faPencil}
                                                                            className="icon edit-icon"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                handleEditEventModal(event.id);
                                                                            }}
                                                                        /></OverlayTrigger>}
                                                                    {canDeleteProgram &&
                                                                        <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>} >
                                                                        <FontAwesomeIcon
                                                                            icon={faTrash}
                                                                            className="icon delete-icon"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation(); // Stop the event propagation
                                                                                handleConfirmDelete(event.id);
                                                                            }}
                                                                        /></OverlayTrigger>}
                                                                </div>}
                                                            </div>
                                                        </div>
                                                        <style>
                                                            {`
                                                            .event-item:nth-child(${index + 1})::before {
                                                                background-color: ${event.color_code};
                                                            }
                                                            `}
                                                        </style>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: '1' }}></div>
                        <div style={{ flex: '1', marginLeft: '20px' }}></div>
                    </div>
                </div>
            </div>

            {/* Modal Create */}
            <Modal className='modal_responsive' show={showModal} onHide={handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Program</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Your form content */}
                    <Form onSubmit={handleSubmitProgram}>
                        <Form.Group controlId="formDivisionName" >
                            <Form.Label className="label-left">Program Name</Form.Label>
                            <Form.Control name="name" value={formData.name} onChange={handleInputChange} type="text" placeholder="Enter program name" required />
                        </Form.Group>
                        <Form.Group controlId="formDivisionName" >
                            <Form.Label className="label-left  mt-3">Description</Form.Label>
                            <Form.Control as="textarea" name="description" value={formData.description} onChange={handleInputChange} type="textarea" placeholder="Enter program description"/>
                        </Form.Group>
                        <Form.Group controlId="formDivisionName" >
                            <Form.Label className="label-left  mt-3">Start Date</Form.Label>
                            <Form.Control name="startDate" value={formData.startDate} onChange={handleInputChange} type="date" placeholder="Enter start date" required />
                        </Form.Group>
                        <Form.Group controlId="formDivisionName" >
                            <Form.Label className="label-left mt-3">End Date</Form.Label>
                            <Form.Control name="endDate" value={formData.endDate} onChange={handleInputChange} type="date" placeholder="Enter end date" required />
                        </Form.Group>
                        <Form.Group controlId="formColor" >
                            <Form.Label className="label-left mt-3">Choose Color</Form.Label>
                            <Form.Control name="color" value={formData.color} onChange={handleInputChange} type="color" placeholder="Choose color" />
                        </Form.Group>

                        <Button variant="primary" type="submit" className="mt-4">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Modal Update */}
            <Modal className='modal_responsive' show={showEditModal} onHide={handleEditModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Program</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Your form content */}
                    <Form onSubmit={handleSubmitEditEvent}>
                        <Form.Group controlId="formDivisionName" >
                            <Form.Label className="label-left">Program Name</Form.Label>
                            <Form.Control name="name" value={formData.name} onChange={handleInputChange} type="text" placeholder="Enter program name" required />
                        </Form.Group>
                        <Form.Group controlId="formDivisionName" >
                            <Form.Label className="label-left  mt-3">Description</Form.Label>
                            <Form.Control as="textarea" name="description" value={formData.description} onChange={handleInputChange} type="textarea" placeholder="Enter program description"/>
                        </Form.Group>
                        <Form.Group controlId="formDivisionName" >
                            <Form.Label className="label-left  mt-3">Start Date</Form.Label>
                            <Form.Control name="startDate" value={formData.startDate} onChange={handleInputChange} type="date" placeholder="Enter start date" required />
                        </Form.Group>
                        <Form.Group controlId="formDivisionName" >
                            <Form.Label className="label-left mt-3">End Date</Form.Label>
                            <Form.Control name="endDate" value={formData.endDate} onChange={handleInputChange} type="date" placeholder="Enter end date" required />
                        </Form.Group>
                        <Form.Group controlId="formColor" >
                            <Form.Label className="label-left mt-3">Choose Color</Form.Label>
                            <Form.Control name="color" value={formData.color} onChange={handleInputChange} type="color" placeholder="Choose color" />
                        </Form.Group>

                        <Button variant="primary" type="submit" className="mt-4">
                            Update
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Modal/Popup for Success Message */}
            <SuccessModal
                className="modal_responsive"
                show={showSuccessModal}
                onHide={closeSuccessModal}
                title="Success"
                modalBody={<p>Data successfully saved!</p>}
                buttonText="Close"
            />

             {/* Modal View */}
             <Modal className='modal_responsive' show={showViewModal} onHide={handleViewModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Program Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Your form content */}
                    <Form>
                        <Form.Group controlId="formDivisionName" >
                            <Form.Label className="label-left">Program Name</Form.Label>
                            <Form.Control name="name" value={formData.name} onChange={handleInputChange} type="text" placeholder="Enter program name" disabled />
                        </Form.Group>
                        <Form.Group controlId="formDivisionName" >
                            <Form.Label className="label-left  mt-3">Description</Form.Label>
                            <Form.Control as="textarea" name="description" value={formData.description} onChange={handleInputChange} type="textarea" placeholder="No description" disabled/>
                        </Form.Group>
                        <Form.Group controlId="formDivisionName" >
                            <Form.Label className="label-left  mt-3">Start Date</Form.Label>
                            <Form.Control name="startDate" value={formData.startDate} onChange={handleInputChange} type="date" placeholder="Enter start date" disabled />
                        </Form.Group>
                        <Form.Group controlId="formDivisionName" >
                            <Form.Label className="label-left mt-3">End Date</Form.Label>
                            <Form.Control name="endDate" value={formData.endDate} onChange={handleInputChange} type="date" placeholder="Enter end date" disabled />
                        </Form.Group>
                        <Form.Group controlId="formColor" >
                            <Form.Label className="label-left mt-3">Choose Color</Form.Label>
                            <Form.Control name="color" value={formData.color} onChange={handleInputChange} type="color" placeholder="Choose color" disabled />
                        </Form.Group>

                        <Button variant="primary" onClick={handleViewModalClose} className="mt-4">
                            Close
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Delete Modal */}
            <Modal className='modal_responsive' show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this event?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseDeleteModal}>
                        Cancel
                    </Button>
                    <Button className="delete-button" onClick={() => handleDeleteEvent(deleteIndex)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal/Popup for Confirmation Delete */}
            <SuccessModal
                className="modal_responsive"
                show={showSuccessDeleteModal}
                onHide={closeSuccessModal}
                title="Data Deleted"
                modalBody={<p>Data was successfully deleted!</p>}
                buttonText="OK"
            />

        </LayoutPage>
    )
}

export default ProgramCalendarPage
