import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../services/AuthProvider";
import LayoutPage from "../../layouts/base"
import { InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import React, { useEffect, useRef, useState } from "react";
import { faBuilding, faEye, faRemove } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from "../../../constants/ApiConfig";
import { useNavigate, useParams } from "react-router-dom";
import { Chart } from "chart.js";
import * as XLSX from 'xlsx';
import { generateRandomColors } from "../../../utils/colorUtils";

function ReportByEachFruitPage() {

    const itemsPerPage = 1;
    const [isLoading, setIsLoading] = useState(true);

    const [searchTerm, setSearchTerm] = useState('');

    const clearSearch = () => {
        setSearchTerm('');
    };

    const { cloneId } = useParams();
    const chartFruitByBlock = useRef([]);

    const [cloneName, setCloneName] = useState('');
    const [selectedDivision, setSelectedDivision] = useState('');
    const [statisticData, setStatisticData] = useState([]);
    const [blockData, setBlockData] = useState([]);
    const [divisionData, setDivisionData] = useState([]);
    const [divisionFruitData, setDivisionFruitData] = useState([]);

    useEffect(() => {
        const fetchStatisticData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}report/fruit`);
                if (response.ok) {
                    const data = await response.json();
                    console.log(data)
                    setStatisticData(data.statistics);

                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchStatisticData();
    }, []);

    useEffect(() => {
        const fetchDivisionData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}division/index`);
                if (response.ok) {
                    const data = await response.json();
                    const divisions = data.divisions;
                    setDivisionData(divisions)

                    if (divisions.length > 0) {
                        setSelectedDivision(divisions[0].id);
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchDivisionData();
    }, []);

    useEffect(() => {
        const fetchBlockData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}block/fetchBlockData/${selectedDivision}`);
                if (response.ok) {
                    const data = await response.json();
                    const blocks = data.blocks;
                    setBlockData(blocks)

                    chartFruitByBlock.current = new Array(blocks.length).fill().map(() => React.createRef());
                    // Populate chartRefs with refs for each block
                    const newChartRefs = {};
                    blocks.forEach(block => {
                        newChartRefs[block.id] = React.createRef();
                    });
                    chartFruitByBlock.current = newChartRefs;
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchBlockData();
    }, [selectedDivision]);

    useEffect(() => {
        const fetchFruitByCloneData = async () => {
            try {
                const url = new URL(`${API_BASE_URL}report/fruit/${cloneId}`);
                url.searchParams.append('selectedDivisionId', selectedDivision);

                const response = await fetch(url);
                if (response.ok) {
                    const data = await response.json();
                    setCloneName(data.clone);
                    const division = data.divisions;
                    setDivisionFruitData(division[0].blocks);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchFruitByCloneData();
    }, [cloneId, selectedDivision, searchTerm]);

    const handleDivisionChange = (e) => {
        setSelectedDivision(e.target.value);
    };

    
    const filteredBlocks = divisionFruitData.filter(block =>
        block.name.toLowerCase().includes(searchTerm.toLowerCase())
    );   

    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredBlocks.slice(indexOfFirstItem, indexOfLastItem);

    const renderIndex = (index) => {
        return (currentPage - 1) * itemsPerPage + index + 1;
    };

    const totalPages = Math.ceil(filteredBlocks.length / itemsPerPage);
    const maxPagesToShow = 5;

    // Change page
    const paginate = (pageNumber) => {
        if (pageNumber < 1 || pageNumber > totalPages) {
            return;
        }
        setCurrentPage(pageNumber);
    };

    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (endPage - startPage + 1 < maxPagesToShow) {
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    const totalEntries = filteredBlocks.length;
    const startEntry = renderIndex(0);
    const endEntry = renderIndex(currentItems.length - 1);

    // Function to generate an array of page numbers to display
    const getPageNumbers = (start, end) => {
        return Array.from({ length: end - start + 1 }, (_, index) => start + index);
    }; 

    // Function to export data to Excel
    const exportToExcel = () => {
        console.log(filteredBlocks); console.log('abel')
        
        const dataWithIndex = [];

        filteredBlocks.forEach((block, index) => {
            const blockData = {
                '#': index + 1,
                'DIVISION': block.division_name || '-',
                'BLOCK': block.name || '-',
                'SUB-BLOCK': '',
                'TOTAL PLANTS': '',
                'PLANT NO': '',
                'NO. OF FRUITS': '',
                'COORDINATE': '',
            };

            dataWithIndex.push(blockData);

            if (block.sub_blocks && block.sub_blocks.length > 0) {
                block.sub_blocks.forEach((subBlock, subIndex) => {
                    if (subIndex === 0) {
                        // If it's the first sub-block, fill in the block-related data
                        blockData['SUB-BLOCK'] = subBlock.name || '-';
                        blockData['TOTAL PLANTS'] = subBlock.plant_count || '0';
                        if (subBlock.plantations.length > 0) {
                            blockData['PLANT NO'] = subBlock.plantations
                                .map((plantation) => `${plantation.plant_no}`)
                                .join('\n');
                            blockData['NO. OF FRUITS'] = subBlock.plantations
                                .map((plantation) => `${plantation.plant_statistics[0].quantity}`)
                                .join('\n');
                            blockData['COORDINATE'] = subBlock.plantations
                                .map((plantation) => `(${plantation.latitude}, ${plantation.longitude})`)
                                .join('\n');
                        } else {
                            blockData['PLANT NO'] = 'No data found';
                            blockData['NO. OF FRUITS'] = 'No data found';
                            blockData['COORDINATE'] = 'No data found';
                        }
                    } else {
                        // If it's not the first sub-block, push a new row with only sub-block-related data
                        dataWithIndex.push({
                            '#': '',
                            'DIVISION': '',
                            'BLOCK': '',
                            'SUB-BLOCK': subBlock.name || '-',
                            'TOTAL PLANTS': subBlock.plant_count || '0',
                            'PLANT NO': subBlock.plantations.length > 0
                                ? subBlock.plantations
                                    .map((plantation) => `${plantation.plant_no}`)
                                    .join('\n')
                            : 'No data found',
                            'NO. OF FRUITS': subBlock.plantations.length > 0
                            ? subBlock.plantations
                                .map((plantation) => `${plantation.plant_statistics[0].quantity}`)
                                .join('\n')
                            : 'No data found',
                            'COORDINATE': subBlock.plantations.length > 0
                                ? subBlock.plantations
                                    .map((plantation) => `(${plantation.latitude}, ${plantation.longitude})`)
                                    .join('\n')
                                : 'No data found',
                        });
                    }
                });
            } else {
                // If there are no sub-blocks, push an empty row
                dataWithIndex.push({
                    '#': '',
                    'DIVISION': '',
                    'BLOCK': '',
                    'SUB-BLOCK': 'No sub-blocks found',
                    'TOTAL PLANTS': '',
                    'PLANT NO': '',
                    'NO. OF FRUITS': '',
                    'COORDINATE': '',
                });
            }
        });
    
        // Convert data to worksheet
        const ws = XLSX.utils.json_to_sheet(dataWithIndex, {
            header: ['#', 'DIVISION', 'BLOCK', 'SUB-BLOCK', 'TOTAL PLANTS', 'PLANT NO', 'NO. OF FRUITS', 'COORDINATE'],
        });

        ws['!cols'] = [{ wch: 5 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 20 }, { wch: 40 }];

        // Check if ws['!rows'] is undefined or not an array, and initialize it if needed
        if (!ws['!rows'] || !Array.isArray(ws['!rows'])) {
            ws['!rows'] = [];
        }

        for (let row = 1; row < filteredBlocks.length; row++) {
            ws['!rows'].push({ hpt: 30 });
        }

        // Generate Excel file
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, cloneId);
        const filename = `report_by_each_clone_${cloneId}.xlsx`;
        XLSX.writeFile(wb, filename);
    }; 

    // Statistics of type chart
    useEffect(() => {
        let newChartCategory;

        const fetchBlockFruitData = async (blockId, index) => {
            try {
                const url = new URL(`${API_BASE_URL}report/fruit/statistics/${blockId}`);
                url.searchParams.append('cloneId', cloneId);

                const response = await fetch(url);
                const data = await response.json();

                const statistics = data.statistics[0].sub_blocks;
                console.log(statistics)

                const labels = statistics.map(item => item.name);  console.log(labels)
                const values = statistics.map(item => item.total_fruits); console.log(values)

                const backgroundColors = generateRandomColors(labels.length);

                const chartData = {
                    labels,
                    datasets: [{
                        data: values,
                        backgroundColor: backgroundColors,
                    }],
                };

                // Configuration options for the pie chart
                const options = {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true,
                        },
                        tooltip: {
                            callbacks: {
                                label: (context) => {
                                    const label = context.label || 'Others';
                                    const value = context.formattedValue || '';
                                                                        
                                    return `${value} Fruits`;
                                },
                            },
                        },
                    },
                };

                // Get the context of the canvas element
                const canvas = chartFruitByBlock.current[blockId]?.current; console.log(canvas)
                if (canvas) {
                    const ctx = canvas.getContext('2d');

                    // Continue with chart creation
                    if (chartFruitByBlock[blockId]?.current) {
                        chartFruitByBlock[blockId]?.current.destroy();
                    }

                    // // Create the pie chart
                    newChartCategory = new Chart(ctx, {
                        type: 'pie',
                        data: chartData,
                        options,
                    });
                } else {
                    console.error("Canvas element not found or not mounted.");
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

       
        blockData.forEach((block, index) => {
            fetchBlockFruitData(block.id, index);
        });

        return async () => {
            if (newChartCategory) {
                await newChartCategory.destroy();
            }
        };
    }, [blockData]);

    const openNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    return (
        <LayoutPage>
            <div className="children">
                {isLoading && (
                    <div className="spinner-overlay">
                        <div className="spinner"></div>
                    </div>
                )}
                <div className="d-flex listTopBar top_bar p-3 bold_poppins justify-content-between">
                    <h4 className="mt-3 pg_title" style={{ textTransform: 'uppercase' }}>
                        {cloneName}
                    </h4>
                    <div className="row" style={{ width: "15%" }}>
                        <div className='col-12'>
                            <Form.Select
                                onChange={handleDivisionChange}
                                value={selectedDivision}
                            >
                                {divisionData.map((division) => (
                                    <option key={division.id} value={division.id}>
                                        {division.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-between p-3">
                    <div>
                        <InputGroup>
                            <Form.Control
                                placeholder="Search..."
                                aria-label=""
                                aria-describedby="basic-addon1"
                                style={{ width: "200px" }}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                value={searchTerm}
                            />
                            {searchTerm && (
                                <InputGroup.Text id="clear-search" onClick={clearSearch}>
                                    <FontAwesomeIcon icon={faRemove} className="icon edit-icon" />
                                </InputGroup.Text>
                            )}
                        </InputGroup>
                    </div>
                    <div className='d-flex text-right'>
                        <button className='eksport_btn ml-2' onClick={exportToExcel}>
                            Export
                        </button>
                    </div>
                </div>
                <div className='m-3 table-container table_responsive'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Block</th>
                                <th>Sub-Block</th>
                                <th className="text-center">Total Plant</th>
                                <th className="text-center">Plant No</th>
                                <th className="text-center">Number of Fruits</th>
                                <th className="text-center">Coordinate (Lat, Long)</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.length > 0 ? (
                                currentItems.map((block, index) => (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td rowSpan={block.sub_blocks?.length || 1}>{renderIndex(index)}</td>
                                            <td rowSpan={block.sub_blocks?.length || 1}>{block.name || ''}</td>
                                            {block.sub_blocks?.[0] && (
                                                <React.Fragment>
                                                    <td>{block.sub_blocks[0].name}</td>
                                                    <td className="text-center">{block.sub_blocks[0].plant_count}</td>
                                                    <td className="text-center">
                                                        {block.sub_blocks[0].plantations.length > 0 ? (
                                                            block.sub_blocks[0].plantations.map((plantation, index) => (
                                                                <div key={index}>
                                                                    {plantation.plant_no}
                                                                </div>
                                                            ))
                                                        ) : (
                                                            0
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {block.sub_blocks[0].plantations.length > 0 ? (
                                                            block.sub_blocks[0].plantations.map((plantation, index) => (
                                                                <div key={index}>
                                                                    {plantation.plant_statistics.length > 0 ? (
                                                                        plantation.plant_statistics[0].quantity
                                                                    ) : (
                                                                        0
                                                                    )}
                                                                </div>
                                                            ))
                                                        ) : (
                                                            '0'
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {block.sub_blocks[0].plantations.length > 0 ? (
                                                            block.sub_blocks[0].plantations.map((plantation, index) => (
                                                                <div key={index}>
                                                                    ({plantation.latitude}, {plantation.longitude})
                                                                </div>
                                                            ))
                                                        ) : (
                                                            'No data found'
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {block.sub_blocks[0].plantations.length > 0 ? (
                                                            block.sub_blocks[0].plantations.map((plantation, index) => (
                                                                <div key={index} className="unwrap editdelete_icon">
                                                                    <FontAwesomeIcon icon={faEye} className="icon edit-icon" onClick={() => openNewTab(`/plantation/view/${plantation.id}`)} />
                                                                </div>
                                                            ))
                                                        ) : (
                                                            ''
                                                        )}
                                                    </td>
                                                </React.Fragment>
                                            )}
                                        </tr>
                                        {/* Render the remaining sub-blocks in separate rows */}
                                        {block.sub_blocks?.slice(1).map((subBlock, subIndex) => (
                                            <tr key={subIndex}>
                                                <td>{subBlock.name}</td>
                                                <td className="text-center">{subBlock.plant_count}</td>
                                                <td className="text-center">
                                                    {subBlock.plantations.length > 0 ? (
                                                        subBlock.plantations.map((plantation, index) => (
                                                            <div key={index}>
                                                                {plantation.plant_no}
                                                            </div>
                                                        ))
                                                    ) : (
                                                        '0'
                                                    )}
                                                </td>
                                                <td className="text-center">
                                                    {subBlock.plantations.length > 0 ? (
                                                        subBlock.plantations.map((plantation, index) => (
                                                            <div key={index}>
                                                                {plantation.plant_statistics.length > 0 ? (
                                                                    plantation.plant_statistics[0].quantity
                                                                ) : (
                                                                    0
                                                                )}
                                                            </div>
                                                        ))
                                                    ) : (
                                                        '0'
                                                    )}
                                                </td>
                                                <td className="text-center">
                                                    {subBlock.plantations.length > 0 ? (
                                                        subBlock.plantations.map((plantation, index) => (
                                                            <div key={index}>
                                                                ({plantation.latitude}, {plantation.longitude})
                                                            </div>
                                                        ))
                                                    ) : (
                                                        'No data found'
                                                    )}
                                                </td>
                                                <td className="text-center">
                                                    {subBlock.plantations.length > 0 ? (
                                                        subBlock.plantations.map((plantation, index) => (
                                                            <div key={index} className="unwrap editdelete_icon">
                                                                <FontAwesomeIcon icon={faEye} className="icon edit-icon" onClick={() => openNewTab(`/plantation/view/${plantation.id}`)} />
                                                            </div>
                                                        ))
                                                    ) : (
                                                        ''
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="8" className="text-center">No data found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="row pt-3 px-4 pb-5">
                    <div className='card w-100'>
                        <div className='card-body'>
                            <h5 className='card-title'>{cloneName} Overview</h5>
                            <div className="my-4 row">
                                {currentItems.map((block, index) => (
                                    <div key={index} className="col-lg-6 col-md-6 col-12">
                                        <h5 style={{ fontFamily: 'Poppins_bold' }}>Block {block.name}</h5>
                                        <div key={block.id}><canvas ref={chartFruitByBlock.current[block.id]}></canvas></div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap-reverse">
                    <div className='col-md-6'>
                        <p className='pl-3' style={{ fontFamily: 'Poppins' }}>
                            Showing {startEntry} to {endEntry} of {totalEntries} entries
                        </p>
                    </div>

                    {/* Pagination component */}
                    <div className='col-md-6 pagination flex-wrap'>
                        <div>
                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                <button onClick={() => paginate(currentPage - 1)} className="page-link">
                                    Previous
                                </button>
                            </li>
                        </div>
                        <div className='d-flex flex-wrap pr-1'>
                            {getPageNumbers(startPage, endPage).map((page) => (
                                <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                                    <button onClick={() => paginate(page)} className="page-link">
                                        {page}
                                    </button>
                                </li>
                            ))}
                        </div>
                        <div >
                            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                <button onClick={() => paginate(currentPage + 1)} className="page-link">
                                    Next
                                </button>
                            </li>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutPage>
    )
}

export default ReportByEachFruitPage