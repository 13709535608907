// import LayoutPage from './Base';
import LayoutPage from '../layouts/base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { API_BASE_URL } from '../../constants/ApiConfig';
import { Button, Form, Modal } from 'react-bootstrap';
import SuccessModal from "../components/success-modal";
import { useAuth } from '../../services/AuthProvider';


function DivisionListingPage() {

    const { user, hasPermission } = useAuth();

    const canManageDivision = hasPermission('manage-division');
    const canCreateDivision = hasPermission('create-division');
    const canEditDivision = hasPermission('edit-division');
    const canDeleteDivision = hasPermission('delete-division');

    const itemsPerPage = 10;

    const [divisionData, setDivisionData] = useState([]);
    const handleDivisionModal = () => setShowModal(true);
    const [divisionName, setDivisionName] = useState('');
    const handleModalClose = () => setShowModal(false);
    const [showModal, setShowModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [editDivisionName, setEditDivisionName] = useState('');
    const [editDivisionId, setEditDivisionId] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const handleEditModalClose = () => setShowEditModal(false);
    const [showSuccessDeleteModal, setshowSuccessDeleteModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);

    useEffect(() => {
        const fetchDivisionData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}division/index`);
                if (response.ok) {
                    const data = await response.json();
                    setDivisionData(data.divisions)
                }
            } catch (error) {
                console.error('Error fetching division data:', error);
            }
        };

        fetchDivisionData();
    }, []);

    const handleNameChange = (e) => {
        setDivisionName(e.target.value);
    };

    const handleSubmitDivision = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_BASE_URL}division/store`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ divisionName }),
            });

            if (response.status == 200) {
                setShowModal(false);
                setShowSuccessModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    }

    const handleEditDivisionModal = (divisionId) => {
        setEditDivisionId(divisionId);
        setShowEditModal(true);
    };

    // Function to fetch zone data by ID
    const fetchDivisionById = async (id) => {
        try {
            const response = await fetch(`${API_BASE_URL}division/edit/${id}`);
            if (response.ok) {
                const data = await response.json();
                console.log(data)
                setEditDivisionName(data.name);
            }
        } catch (error) {
            console.error('Error fetching zone:', error);
        }
    };

    useEffect(() => {
        if (editDivisionId !== null) {
            fetchDivisionById(editDivisionId);
        }
    }, [editDivisionId]);

    const handleEditedNameChange = (event) => {
        setEditDivisionName(event.target.value);
    };

    const handleSubmitEditDivision = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_BASE_URL}division/update/${editDivisionId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ divisionName: editDivisionName }),
            });

            if (response.ok) {
                setShowEditModal(false);
                setShowSuccessModal(true);
            }
        } catch (error) {
            // Handle fetch errors
            console.error('Error updating zone:', error);
        }
    }

    const handleDeleteDivision = async (index) => {
        try {
            const response = await fetch(`${API_BASE_URL}division/destroy/${index}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status == 200) {
                setShowDeleteModal(false);
                setshowSuccessDeleteModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setDeleteIndex(null);
    };

    const handleConfirmDelete = (id) => {
        setDeleteIndex(id);
        setShowDeleteModal(true);
    };


    const closeSuccessModal = () => {
        setShowSuccessModal(false);
        window.location.reload();
    };

    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = Array.isArray(divisionData)
        ? divisionData.slice(indexOfFirstItem, indexOfLastItem)
        : [];

    const renderIndex = (index) => {
        return (currentPage - 1) * itemsPerPage + index + 1;
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    return (
        <LayoutPage>
            <div className="d-flex listTopBar top_bar p-3 bold_poppins justify-content-between">
                <h4 className="mt-3 pg_title">
                    LIST OF DIVISIONS
                </h4>
            </div>
            <div className='children'>
                {canCreateDivision &&
                    <div className="addDisease d-flex justify-content-center mb-4" onClick={handleDivisionModal}>
                        <div className="icon">
                            <FontAwesomeIcon className="icon" icon={faPlus} />
                        </div>
                        <div className="text ml-3">
                            Division
                        </div>
                    </div>
                }

                <div>
                    {Array.isArray(currentItems) && currentItems.length > 0 ? (
                        <div>
                            {currentItems.map((division, index) => (
                                <div key={index} className="listBar p-3 my-3">
                                    <div className="d-flex justify-content-between mx-4">
                                        <div className="regular_poppins">
                                            {renderIndex(index)}. {division.name}
                                        </div>
                                        <div className="regular_poppins">
                                            {canEditDivision && <FontAwesomeIcon
                                                icon={faPencil}
                                                className="icon edit-icon"
                                                onClick={() => handleEditDivisionModal(division.id)}
                                            />}
                                            {canDeleteDivision && <FontAwesomeIcon
                                                icon={faTrash}
                                                className="icon delete-icon"
                                                onClick={() => handleConfirmDelete(division.id)}
                                            />}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className='text-center' style={{ fontFamily: 'Poppins' }}>No data found</p>
                    )}
                    <div className='pagination'>
                        <button onClick={handlePrevPage} disabled={currentPage === 1}>
                            Prev
                        </button>
                        <button onClick={handleNextPage} disabled={divisionData?.length ? indexOfLastItem >= divisionData.length : true}>
                            Next
                        </button>
                    </div>
                </div>

            </div>

            {/* Modal Create Division */}
            <Modal className='modal_responsive' show={showModal} onHide={handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Division</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Your form content */}
                    <Form onSubmit={handleSubmitDivision}>
                        <Form.Group controlId="formDivisionName" >
                            <Form.Label className="label-left">Division Name</Form.Label>
                            <Form.Control value={divisionName} onChange={handleNameChange} type="text" placeholder="Enter division name" required />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-4">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Modal Update Zone */}
            <Modal className='modal_responsive' show={showEditModal} onHide={handleEditModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Division</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmitEditDivision}>
                        <Form.Group controlId="formDivisionName" >
                            <Form.Label className="label-left">Division Name</Form.Label>
                            <Form.Control
                                value={editDivisionName}
                                onChange={handleEditedNameChange}
                                type="text"
                                placeholder="Enter division name"
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-4">
                            Update
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Modal/Popup for Success Message */}
            <SuccessModal
                className="modal_responsive"
                show={showSuccessModal}
                onHide={closeSuccessModal}
                title="Success"
                modalBody={<p>Data successfully saved!</p>}
                buttonText="Close"
            />

            {/* Delete Modal */}
            <Modal className='modal_responsive' show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this division?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseDeleteModal}>
                        Cancel
                    </Button>
                    <Button className="delete-button" onClick={() => handleDeleteDivision(deleteIndex)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal/Popup for Confirmation Delete */}
            <SuccessModal
                className="modal_responsive"
                show={showSuccessDeleteModal}
                onHide={closeSuccessModal}
                title="Data Deleted"
                modalBody={<p>Data was successfully deleted!</p>}
                buttonText="OK"
            />

        </LayoutPage>
    )
}

export default DivisionListingPage