// BlockIdContext.js

import { createContext, useContext, useState } from 'react';

const BlockIdContext = createContext();

export const BlockIdProvider = ({ children }) => {
  const [blockId, setBlockId] = useState(null);
  const [divisionId, setDivisionId] = useState(null);
  const [subBlockId, setSubBlockId] = useState(null);
  const [isSet, setIsSet] = useState(false);

  const setBlockAndDivisionId = (blockId, divisionId, subBlockId) => {
    setBlockId(blockId);
    setDivisionId(divisionId);
    setSubBlockId(subBlockId)
    setIsSet(true);
  };

  return (
    <BlockIdContext.Provider value={{ blockId, setBlockId, divisionId, setDivisionId, subBlockId, setSubBlockId, setBlockAndDivisionId, isSet }}>
      {children}
    </BlockIdContext.Provider>
  );
};

export const useBlockId = () => {
  const context = useContext(BlockIdContext);
  if (!context) {
    throw new Error('useBlockId must be used within a BlockIdProvider');
  }
  return context;
};
