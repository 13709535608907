import React, { useState, useEffect, useRef } from "react";
import data_vector from "../../../assets/combined.json";
import searchsvg from "../../../assets/search.svg";

import GeoJSON from "ol/format/GeoJSON.js";
const QueryTool = ({ vectorLayer, map }) => {
  const jsonData = data_vector.features;
  const filterKeys = ["KLON", "TAHUN DITA", "NO BLOK", "BLOK", "DIV", "STATUS"];
  const result_data = {};
  const toolRef = React.createRef();
  const cardRef = React.createRef();
  const refSelect = {};

  filterKeys.forEach((key) => {
    result_data[key] = [...new Set(jsonData.map((obj) => obj.properties[key]))];
    refSelect[key] = React.createRef();
  });
  let selectedQuery = {};

  const handleSelectChange = (selected) => {
    selectedQuery[selected.target.getAttribute("data-key")] =
      selected.target.value;
    if (selected.target.value == "") {
      delete selectedQuery[selected.target.getAttribute("data-key")];
    }
  };

  const opts = Object.keys(result_data).map((key) => (
    <div className="d-flex align-items-center" key={"div" + key}>
      <label htmlFor="" className="col-form-label me-2" key={"label" + key}>
        {key}
      </label>
      <select
        key={"select" + key}
        ref={refSelect[key]}
        data-key={key}
        className="form-select form-select-sm"
        onChange={handleSelectChange}
      >
        <option value="">Select Option</option>
        {result_data[key].map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  ));

  const toolToggle = () => {
    Object.keys(refSelect).forEach((ref) => {
      refSelect[ref].current.value = "";
    });
    toolRef.current.classList.toggle("d-none");
    cardRef.current.classList.toggle("d-none");
    selectedQuery = {};
  };

  const executeQuery = () => {
    const filteredData = data_vector.features.filter((item) => {
      let filtered = true;
      Object.keys(selectedQuery).forEach((key) => {
        if (item.properties[key] != selectedQuery[key]) {
          filtered = false;
        }
      });
      return filtered;
    });
    if (filteredData) {
      updateGeometry(filteredData);
    }
  };

  const updateGeometry = (payload) => {
    vectorLayer.layer.getSource().clear();
    vectorLayer.layer
      .getSource()
      .addFeatures(
        new GeoJSON().readFeatures({ ...data_vector, features: payload })
      );
    map.getView().fit(vectorLayer.layer.getSource().getExtent(), map.getSize());
  };

  const clearQuery = () => {
    Object.keys(refSelect).forEach((ref) => {
      refSelect[ref].current.value = "";
      selectedQuery = {};
    });
    updateGeometry(data_vector.features);
  };

  return (
    <>
      <div className="query-container z-1 d-flex flex-row-reverse">
        <div className="d-block">
          <button
            title="Query tool"
            ref={toolRef}
            className="btn search-tool"
            style={{ padding: "0px" }}
            onClick={toolToggle}
          >
            <img style={{ width: "38px" }} src={searchsvg} />
          </button>
        </div>
        <div className="card d-none" ref={cardRef}>
          <div className="card-header d-flex">
            <label>
              <img style={{ width: "24px" }} src={searchsvg} /> Query
            </label>
            <button
              className="btn-close ms-auto"
              aria-label="Close"
              onClick={toolToggle}
            ></button>
          </div>
          <div id="query-body" className="card-body">
            {opts}
            <div className="d-flex justify-content-between">
              <button
                className="btn btn-outline-secondary"
                onClick={clearQuery}
              >
                clear
              </button>
              <button
                className="btn btn-outline-primary"
                onClick={executeQuery}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QueryTool;
