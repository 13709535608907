import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../services/AuthProvider";
import LayoutPage from "../../layouts/base";
import { InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import React, { useEffect, useRef, useState } from "react";
import { faBuilding, faEdit, faEye, faPencil, faQrcode, faRemove } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from "../../../constants/ApiConfig";
import { useNavigate, useParams } from "react-router-dom";
import { Chart } from "chart.js";
import * as XLSX from 'xlsx';
import { generateRandomColors } from "../../../utils/colorUtils";

function ReportByEachPhasePage() {

    const itemsPerPage = 1;
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const { user, hasPermission } = useAuth();
    const canManageReport = hasPermission('manage-report');
    const canEditPlantation = hasPermission('edit-plantation');

    const { phaseId } = useParams();
    const chartPhaseByBlock = useRef([]);

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedDivision, setSelectedDivision] = useState('');
    const [phaseData, setPhaseData] = useState([]);
    const [blockData, setBlockData] = useState([]);
    const [divisionData, setDivisionData] = useState([]);
    const [divisionPhaseData, setDivisionPhaseData] = useState([]);

    const clearSearch = () => {
        setSearchTerm('');
    };

    useEffect(() => {
        const fetchDivisionData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}division/index`);
                if (response.ok) {
                    const data = await response.json();
                    const divisions = data.divisions;
                    setDivisionData(divisions)

                    if (divisions.length > 0) {
                        setSelectedDivision(divisions[0].id);
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchDivisionData();
    }, []);

    useEffect(() => {
        const fetchBlockData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}block/fetchBlockData/${selectedDivision}`);
                if (response.ok) {
                    const data = await response.json();
                    const blocks = data.blocks;
                    setBlockData(blocks)

                    chartPhaseByBlock.current = new Array(blocks.length).fill().map(() => React.createRef());
                    // Populate chartRefs with refs for each block
                    const newChartRefs = {};
                    blocks.forEach(block => {
                        newChartRefs[block.id] = React.createRef();
                    });
                    chartPhaseByBlock.current = newChartRefs;
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchBlockData();
    }, [selectedDivision]);

    useEffect(() => {
        const fetchPhaseByIdData = async () => {
            try {
                const url = new URL(`${API_BASE_URL}report/phase/${phaseId}`);
                url.searchParams.append('selectedDivisionId', selectedDivision);

                const response = await fetch(url);
                if (response.ok) {
                    const data = await response.json();
                    const division = data.divisions;
                    
                    setPhaseData(data.phase);
                    setDivisionPhaseData(division[0].blocks);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchPhaseByIdData();
    }, [phaseId, selectedDivision, searchTerm]);

    const handleDivisionChange = (e) => {
        setSelectedDivision(e.target.value);
    };

    const filteredBlocks = divisionPhaseData.filter(block => {
        // Check if block name matches the search term
        const blockMatches = block.name.toLowerCase().includes(searchTerm.toLowerCase());
    
        // Check if any of the subblocks or their plantations match the search term
        const subBlocksMatch = block.sub_blocks?.some(subBlock => {
            // Check if the subBlock name matches the search term
            const subBlockMatches = subBlock.name.toLowerCase().includes(searchTerm.toLowerCase());
    
            // Check if any plantations in the subBlock match the search term
            const plantationsMatch = subBlock.plantations.some(plantation => 
                plantation.plant_no.toLowerCase().includes(searchTerm.toLowerCase()) ||
                plantation.clone_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                plantation.latitude.toString().includes(searchTerm) ||  // latitude as string
                plantation.longitude.toString().includes(searchTerm)   // longitude as string
            );
    
            // Return true if either the subBlock matches or any plantation matches
            return subBlockMatches || plantationsMatch;
        });
    
        // Return true if either the block matches or any of its sub-blocks match
        return blockMatches || subBlocksMatch;
    });

    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredBlocks.slice(indexOfFirstItem, indexOfLastItem);

    const renderIndex = (index) => {
        return (currentPage - 1) * itemsPerPage + index + 1;
    };

    const totalPages = Math.ceil(filteredBlocks.length / itemsPerPage);
    const maxPagesToShow = 5;

    // Change page
    const paginate = (pageNumber) => {
        if (pageNumber < 1 || pageNumber > totalPages) {
            return;
        }
        setIsLoading(true);
        setCurrentPage(pageNumber);
    };

    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (endPage - startPage + 1 < maxPagesToShow) {
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    const totalEntries = filteredBlocks.length;
    const startEntry = renderIndex(0);
    const endEntry = renderIndex(currentItems.length - 1);

    // Function to generate an array of page numbers to display
    const getPageNumbers = (start, end) => {
        return Array.from({ length: end - start + 1 }, (_, index) => start + index);
    };       

    // Function to export data to Excel
    const exportToExcel = () => {
        
        const dataWithIndex = [];

        filteredBlocks.forEach((block, index) => {
            const blockData = {
                '#': index + 1,
                'DIVISION': block.division_name || '-',
                'BLOCK': block.name || '-',
                'SUB-BLOCK': '',
                'TOTAL PLANTS': '',
                'COORDINATE': '',
            };

            dataWithIndex.push(blockData);

            if (block.sub_blocks && block.sub_blocks.length > 0) {
                block.sub_blocks.forEach((subBlock, subIndex) => {
                    if (subIndex === 0) {
                        // If it's the first sub-block, fill in the block-related data
                        blockData['SUB-BLOCK'] = subBlock.name || '-';
                        blockData['TOTAL PLANTS'] = subBlock.plant_count || '0';
                        if (subBlock.plantations.length > 0) {
                            blockData['COORDINATE'] = subBlock.plantations
                                .map((plantation) => `(${plantation.latitude}, ${plantation.longitude})`)
                                .join(', ');
                        } else {
                            blockData['COORDINATE'] = 'No data found';
                        }
                    } else {
                        // If it's not the first sub-block, push a new row with only sub-block-related data
                        dataWithIndex.push({
                            '#': '',
                            'DIVISION': '',
                            'BLOCK': '',
                            'SUB-BLOCK': subBlock.name || '-',
                            'TOTAL PLANTS': subBlock.plant_count || '0',
                            'PLANT NO': subBlock.plantations.length > 0
                                ? subBlock.plantations
                                    .map((plantation) => `${plantation.plant_no}`)
                                    .join(', ')
                                : 'No data found',
                            'CLONE': subBlock.plantations.length > 0
                                ? subBlock.plantations
                                    .map((plantation) => `${plantation.clone_name}`)
                                    .join(', ')
                                : 'No data found',
                            'COORDINATE': subBlock.plantations.length > 0
                                ? subBlock.plantations
                                    .map((plantation) => `(${plantation.latitude}, ${plantation.longitude})`)
                                    .join(', ')
                                : 'No data found',
                        });
                    }
                });
            } else {
                // If there are no sub-blocks, push an empty row
                dataWithIndex.push({
                    '#': '',
                    'DIVISION': '',
                    'BLOCK': '',
                    'SUB-BLOCK': 'No sub-blocks found',
                    'TOTAL PLANTS': '',
                    'PLANT NO': '',
                    'CLONE': '',
                    'COORDINATE': '',
                });
            }
        });
    
        // Convert data to worksheet
        const ws = XLSX.utils.json_to_sheet(dataWithIndex, {
            header: ['#', 'DIVISION', 'BLOCK', 'SUB-BLOCK', 'TOTAL PLANTS', 'PLANT NO', 'CLONE', 'COORDINATE'],
        });

        ws['!cols'] = [{ wch: 5 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 20 }, { wch: 20 }, { wch: 40 }];

        // Generate Excel file
        const maxLength = 31;
        let phaseName = phaseData.name || 'default_phase';

        if (phaseName.length > maxLength) {
            phaseName = phaseName.slice(0, maxLength);
        }

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, phaseName);

        const filename = `report_by_phase_${phaseName}.xlsx`;

        console.log("Final filename:", filename); 

        XLSX.writeFile(wb, filename);
    };  

    // Statistics of type chart
    useEffect(() => {
        let newChartCategory;

        const fetchBlockPhaseData = async (blockId, index) => {
            try {
                setIsLoading(true);
                
                const url = new URL(`${API_BASE_URL}report/phase/statistics/${blockId}`);
                url.searchParams.append('phaseId', phaseId);

                const response = await fetch(url);
                const data = await response.json();

                const statistics = data.statistics[0].sub_blocks;

                const labels = statistics.map(item => item.name);  
                const values = statistics.map(item => item.plant_count);

                const backgroundColors = generateRandomColors(labels.length);

                const chartData = {
                    labels,
                    datasets: [{
                        data: values,
                        backgroundColor: backgroundColors,
                    }],
                };

                // Configuration options for the pie chart
                const options = {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true,
                        },
                        tooltip: {
                            callbacks: {
                                label: (context) => {
                                    const label = context.label || 'Others';
                                    const value = context.formattedValue || '';
                                                                        
                                    return `${value} Plant`;
                                },
                            },
                        },
                    },
                };

                // Get the context of the canvas element
                const canvas = chartPhaseByBlock.current[blockId]?.current;
                if (canvas) {
                    const ctx = canvas.getContext('2d');

                    // Continue with chart creation
                    if (chartPhaseByBlock[blockId]?.current) {
                        chartPhaseByBlock[blockId]?.current.destroy();
                    }

                    setIsLoading(false); 

                    // // Create the pie chart
                    newChartCategory = new Chart(ctx, {
                        type: 'pie',
                        data: chartData,
                        options,
                    });
                } else {
                    console.error("Canvas element not found or not mounted.");
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

       
        blockData.forEach((block, index) => {
            fetchBlockPhaseData(block.id, index);
        });

        return async () => {
            if (newChartCategory) {
                await newChartCategory.destroy();
            }
        };
    }, [blockData]);

    const openNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    const highlightKeywords = (text, searchTerm) => {
        if (!searchTerm) return text; // Return original text if there's no search term
    
        const regex = new RegExp(`(${searchTerm})`, 'gi'); // Create a regex for case-insensitive matching
        const parts = text.split(regex); // Split the text by the search term
    
        return parts.map((part, index) => 
            part.toLowerCase() === searchTerm.toLowerCase() ? (
                <span key={index} className="highlight">{part}</span> // Highlight the search term
            ) : part // Return non-highlighted text as is
        );
    };


    return (
        <LayoutPage>
            <div className="children">
                {/* {isLoading && (
                    <div className="spinner-overlay">
                        <div className="spinner"></div>
                    </div>
                )} */}
                <div className="d-flex listTopBar top_bar p-3 bold_poppins justify-content-between">
                    <h4 className="mt-3 pg_title" style={{ textTransform: 'uppercase' }}>
                        {phaseData.name}
                    </h4>
                    <div className="row" style={{ width: "15%" }}>
                        <div className='col-12'>
                            <Form.Select
                                onChange={handleDivisionChange}
                                value={selectedDivision}
                            >
                                {divisionData.map((division) => (
                                    <option key={division.id} value={division.id}>
                                        {division.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between p-3">
                    <div>
                        <InputGroup>
                            <Form.Control
                                placeholder="Search..."
                                aria-label=""
                                aria-describedby="basic-addon1"
                                style={{ width: "200px" }}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                value={searchTerm}
                            />
                            {searchTerm && (
                                <InputGroup.Text id="clear-search" onClick={clearSearch}>
                                    <FontAwesomeIcon icon={faRemove} className="icon edit-icon" />
                                </InputGroup.Text>
                            )}
                        </InputGroup>
                    </div>
                    <div className='d-flex text-right'>
                        <button className='eksport_btn ml-2' onClick={exportToExcel}>
                            Export
                        </button>
                    </div>
                </div>
                <div className='m-3 table-container table_responsive'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Block</th>
                                <th>Sub-Block</th>
                                <th className="text-center">Total Plants</th>
                                <th className="text-center">Plant No</th>
                                <th className="text-center">Clone</th>
                                <th className="text-center">Coordinate (Lat, Long)</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.length > 0 ? (
                                currentItems.map((block, index) => {
                                    // Calculate the total plants for each block
                                    const totalPlants = block.sub_blocks?.reduce((sum, subBlock) => sum + Number(subBlock.plant_count), 0) || 0;
                                    
                                    return (
                                        <React.Fragment key={index}>
                                            <tr>
                                                <td rowSpan={block.sub_blocks?.length || 1}>{renderIndex(index)}</td>
                                                <td rowSpan={block.sub_blocks?.length || 1}>{highlightKeywords(block.name, searchTerm) || ''}</td>
                                                {block.sub_blocks?.[0] && (
                                                    <React.Fragment>
                                                        <td>{highlightKeywords(block.sub_blocks[0].name, searchTerm)}</td>
                                                        <td className="text-center">{block.sub_blocks[0].plant_count}</td>
                                                        <td className="text-center">
                                                            {block.sub_blocks[0].plantations.length > 0 ? (
                                                                block.sub_blocks[0].plantations.map((plantation, index) => (
                                                                    <div key={index}>
                                                                        {highlightKeywords(plantation.plant_no, searchTerm)}
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                'No data found'
                                                            )}
                                                        </td>
                                                        <td className="text-center">
                                                            {block.sub_blocks[0].plantations.length > 0 ? (
                                                                block.sub_blocks[0].plantations.map((plantation, index) => (
                                                                    <div key={index}>
                                                                        {highlightKeywords(plantation.clone_name, searchTerm)}
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                'No data found'
                                                            )}
                                                        </td>
                                                        <td className="text-center">
                                                            {block.sub_blocks[0].plantations.length > 0 ? (
                                                                block.sub_blocks[0].plantations.map((plantation, index) => (
                                                                    <div key={index}>
                                                                        {highlightKeywords(`(${plantation.latitude}, ${plantation.longitude})`, searchTerm)}
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                'No data found'
                                                            )}
                                                        </td>
                                                        <td className="text-center">
                                                            {block.sub_blocks[0].plantations.length > 0 ? (
                                                                block.sub_blocks[0].plantations.map((plantation, index) => (
                                                                    <div key={index} className="unwrap editdelete_icon">
                                                                        <FontAwesomeIcon icon={faEye} className="icon edit-icon" onClick={() => openNewTab(`/plantation/view/${plantation.id}`)} />
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                ''
                                                            )}
                                                        </td>
                                                    </React.Fragment>
                                                )}
                                            </tr>
                                            {/* Render the remaining sub-blocks in separate rows */}
                                            {block.sub_blocks?.slice(1).map((subBlock, subIndex) => (
                                                <tr key={subIndex}>
                                                    <td>{highlightKeywords(subBlock.name, searchTerm)}</td>
                                                    <td className="text-center">{subBlock.plant_count}</td>
                                                    <td className="text-center">
                                                        {subBlock.plantations.length > 0 ? (
                                                            subBlock.plantations.map((plantation, index) => (
                                                                <div key={index}>
                                                                    {highlightKeywords(plantation.plant_no, searchTerm)}
                                                                </div>
                                                            ))
                                                        ) : (
                                                            'No data found'
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {subBlock.plantations.length > 0 ? (
                                                            subBlock.plantations.map((plantation, index) => (
                                                                <div key={index}>
                                                                    {highlightKeywords(plantation.clone_name, searchTerm)}
                                                                </div>
                                                            ))
                                                        ) : (
                                                            'No data found'
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {subBlock.plantations.length > 0 ? (
                                                            subBlock.plantations.map((plantation, index) => (
                                                                <div key={index}>
                                                                    {highlightKeywords(`(${plantation.latitude}, ${plantation.longitude})`, searchTerm)}
                                                                </div>
                                                            ))
                                                        ) : (
                                                            'No data found'
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {subBlock.plantations.length > 0 ? (
                                                            subBlock.plantations.map((plantation, index) => (
                                                                <div key={index} className="unwrap editdelete_icon">
                                                                    <FontAwesomeIcon icon={faEye} className="icon edit-icon" onClick={() => openNewTab(`/plantation/view/${plantation.id}`)} />
                                                                    {canEditPlantation && <FontAwesomeIcon icon={faPencil} className="icon edit-icon" onClick={() => navigate(`/plantation/edit/${plantation.id}`)} />}
                                                                </div>
                                                            ))
                                                        ) : (
                                                            ''
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                            {/* Total Row for Each Block */}
                                            <tr>
                                                <td colSpan="3" className="text-center"><strong>Total Plants for Block {block.name}</strong></td>
                                                <td className="text-center"><strong>{totalPlants}</strong></td>
                                                <td colSpan="4"></td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                })
                            ) : (
                                <tr>
                                    <td colSpan="8" className="text-center">No data found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="row pt-3 px-4 pb-5">
                    <div className='card w-100'>
                        <div className='card-body'>
                            <h5 className='card-title'>{phaseData.name} Overview</h5>
                            <div className="chart-column"> {/* Add chart-column class here */}
                                {!isLoading && (
                                    <div className="my-4 row">
                                        {currentItems.map((block, index) => (
                                            <div key={index} className="col-lg-6 col-md-6 col-12">
                                                <h5 style={{ fontFamily: 'Poppins_bold' }}>Block {block.name}</h5>
                                                <div key={block.id}><canvas ref={chartPhaseByBlock.current[block.id]}></canvas></div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {isLoading && (
                                    <div className="spinner-overlay">
                                        <div className="spinner"></div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap-reverse">
                    <div className='col-md-6'>
                        <p className='pl-3' style={{ fontFamily: 'Poppins' }}>
                            Showing {startEntry} to {endEntry} of {totalEntries} entries
                        </p>
                    </div>

                    {/* Pagination component */}
                    <div className='col-md-6 pagination flex-wrap'>
                        <div>
                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                <button onClick={() => paginate(currentPage - 1)} className="page-link">
                                    Previous
                                </button>
                            </li>
                        </div>
                        <div className='d-flex flex-wrap pr-1'>
                            {getPageNumbers(startPage, endPage).map((page) => (
                                <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                                    <button onClick={() => paginate(page)} className="page-link">
                                        {page}
                                    </button>
                                </li>
                            ))}
                        </div>
                        <div >
                            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                <button onClick={() => paginate(currentPage + 1)} className="page-link">
                                    Next
                                </button>
                            </li>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutPage>
    )
}

export default ReportByEachPhasePage
