// import React from 'react';
import Form from 'react-bootstrap/Form';
import React, { useEffect, useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faDashboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../services/AuthProvider';


const Sidebar = ({ sidebarOpen, closeSidebar }) => {

    const { user, hasPermission } = useAuth();

    const canViewDashboard = hasPermission('view-dashboard');
    const canManageBlock = hasPermission('manage-block');
    const canManagePlantation = hasPermission('manage-plantation');
    const canManageDisease = hasPermission('manage-disease');
    const canManageRole = hasPermission('manage-role');
    const canManageUser = hasPermission('manage-user');
    const canManageDivision = hasPermission('manage-division');
    const canManageSubBlock = hasPermission('manage-subblock');
    const canManageProgram = hasPermission('manage-program');
    const canManageReport= hasPermission('manage-report');
    const canManageClone= hasPermission('manage-clone');
    const canManagePhase= hasPermission('manage-phase');
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const [isMenuClicked, setIsMenuClicked] = useState(false);
    const [clickedSubmenuItem, setClickedSubmenuItem] = useState('');

    const location = useLocation();

    const redirectUrl = localStorage.getItem('redirectUrl');

    const toggleSubMenu = () => {
        setIsSubMenuOpen(!isSubMenuOpen);
        setIsMenuClicked(true);
    };

    const handleSubmenuItemClicked = (menuItem) => {
        setClickedSubmenuItem(menuItem);
    };

    useEffect(() => {
        // Check if the current URL matches any submenu item
        const currentUrl = window.location.pathname;
        const submenuItems = ['/report/division', '/report/clone', '/report/disease', '/report/plantation', '/report/fruit', '/report/phase'];
        if (submenuItems.includes(currentUrl)) {
            setIsSubMenuOpen(true);
            setIsMenuClicked(true);
            setClickedSubmenuItem(currentUrl);
        }
    }, []);

    useEffect(() => {
        const handleNavClick = (e) => {
            const element = e.target;
            const nextEl = element.nextElementSibling;
            const parentEl = element.parentElement;

            if (nextEl) {
                e.preventDefault();
                const mycollapse = new window.bootstrap.Collapse(nextEl);

                if (nextEl.classList.contains('show')) {
                    mycollapse.hide();
                } else {
                    mycollapse.show();
                    // Find other submenus with class=show
                    const openedSubmenu = parentEl.parentElement.querySelector('.submenu.show');
                    // If it exists, close all of them
                    if (openedSubmenu) {
                        new window.bootstrap.Collapse(openedSubmenu);
                    }
                }
            }
        };
        document.querySelectorAll('.sidebar .nav-link').forEach((element) => {
            element.addEventListener('click', handleNavClick);
        });

        return () => {
            document.querySelectorAll('.sidebar .nav-link').forEach((element) => {
                element.removeEventListener('click', handleNavClick);
            });
        };
    }, []);

    const isDashboardPage = location.pathname === '/dashboard';
    const isqrPage = location.pathname === '/qrScan';
    const isQRgeneratorpage = location.pathname === '/plantation/QRgenerator';
    const isFormpage = location.pathname === '/form';
    const isDiseasepage = location.pathname === '/disease';
    const isPlantationListPage = location.pathname === '/plantation';
    const isBlockPage = location.pathname === '/block';
    const isManageUserPage = location.pathname === '/user';
    const isRoleListPage = location.pathname === '/role';
    const isDivisionPage = location.pathname === '/division';
    const isSubBlockPage = location.pathname == '/block/sub-block/:blockId';
    const isManageProgramPage = location.pathname === '/program';
    const isClonepage = location.pathname === '/clone';
    const isPhasepage = location.pathname === '/phase';

    const sidebarStyle = {
        width: sidebarOpen ? '250px' : '80px',
        display: sidebarOpen ? 'block' : 'block',
        left: 0, // Set the right property to 0 for right-aligned sidebar
    };
    const sidebarStyle2 = {
        width: sidebarOpen ? '100%' : '80px',
        display: sidebarOpen ? 'block' : 'block',
        left: 0, // Set the right property to 0 for right-aligned sidebar


    };

    return (
        <div>
            <div className="w3-sidebar w3-bar-block w3-card w3-animate-right sidebar" style={sidebarStyle} id="mySidebar">

                {/* <button className="w3-bar-item w3-button w3-large" onClick={closeSidebar}>Close &times;</button> */}
                <div className='sidebar_headerqr'>
                    {/* <label className='bold_poppins text-black'>History</label> */}
                    <div className='sidebar_scroll2'>
                        <div className='d-flex sidebardiv_main_logo top_bar'>
                            <div className='text-center'>
                                <img
                                    src="/images/main_logo.png"
                                    className="img-fluid rounded-start sidebar_main_logo"
                                    alt="..."
                                />
                            </div>
                            SABAHEBAT
                        </div>

                        {canViewDashboard && <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar  ${isDashboardPage ? 'active' : ''}`} href="/dashboard">
                            <div className='icon_sidebar text-center'>
                                <i className="ri-dashboard-2-line"></i>
                            </div>
                            Dashboard
                        </a>}

                        {!redirectUrl && (
                            <>
                                {canManageDivision && <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar ${isDivisionPage ? 'active' : ''}`} href="/division">
                                    <div className='icon_sidebar text-center'>
                                        <i className="ri-earth-line"></i>
                                    </div>
                                    Divisions
                                </a>}

                                {canManageBlock && <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar ${isBlockPage || isSubBlockPage ? 'active' : ''} ${isSubBlockPage ? 'subActive' : ''}`} href="/block">
                                    <div className='icon_sidebar text-center'>
                                        <i className="ri-building-4-line"></i>
                                    </div>
                                    Blocks
                                </a>}

                                {canManagePlantation && <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar ${isPlantationListPage ? 'active' : ''}`} href="/plantation">
                                    <div className='icon_sidebar text-center'>
                                        <i className="ri-plant-line"></i>
                                    </div>
                                    Plantation
                                </a>}
                                {canManagePlantation && <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar ${isQRgeneratorpage ? 'active' : ''}`} href="/plantation/QRgenerator">
                                    <div className='icon_sidebar text-center'>
                                        <i className="ri-qr-code-line"></i>
                                    </div>
                                    QR Generator
                                </a>}

                                {canManageProgram && <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar ${isManageProgramPage ? 'active' : ''}`} href="/program">
                                    <div className='icon_sidebar text-center'>
                                        <i className="ri-calendar-2-line"></i>                           </div>
                                    Programs
                                </a>}

                                {canManageDisease && <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar ${isDiseasepage ? 'active' : ''}`} href="/disease">
                                    <div className='icon_sidebar text-center'>
                                        <i className="ri-bug-2-line"></i>                            </div>
                                    Diseases
                                </a>}

                                {canManageClone && <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar ${isClonepage ? 'active' : ''}`} href="/clone">
                                    <div className='icon_sidebar text-center'>
                                        <i className="ri-seedling-line"></i>                            </div>
                                    Clones
                                </a>}

                                {canManagePhase && <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar ${isPhasepage ? 'active' : ''}`} href="/phase">
                                    <div className='icon_sidebar text-center'>
                                    <i class="ri-swap-3-line"></i>                    </div>
                                    Phases
                                </a>}

                                {canManageRole && <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar ${isRoleListPage ? 'active' : ''}`} href="/role">
                                    <div className='icon_sidebar text-center'>
                                        <i className="ri-shield-user-line"></i>                           </div>
                                    Roles
                                </a>}

                                {canManageUser && <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar ${isManageUserPage ? 'active' : ''}`} href="/user">
                                    <div className='icon_sidebar text-center'>
                                        <i className="ri-user-line"></i>                           </div>
                                    Users
                                </a>}

                                {canManageReport && (
                                    <div>
                                        <a
                                            className={`text-dark regular_poppins text-decoration-none d-flex top_bar head_menu ${isMenuClicked ? 'clicked' : ''}`}
                                            href="#"
                                            onClick={toggleSubMenu}
                                        >
                                            <div className='icon_sidebar text-center'>
                                                <i className="ri-file-chart-line"></i>
                                            </div>
                                            Reports
                                        </a>
                                        {isSubMenuOpen && (
                                            <div className="submenu">
                                                <ul>
                                                    <li className={`${clickedSubmenuItem === '/report/division' ? 'clicked' : ''}`}>
                                                        <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar`} href="/report/division">
                                                            {!sidebarOpen && <i className="ri-earth-line icon_submenu"></i>}
                                                            {sidebarOpen && <span id='submenu_name'>Divisions</span>}
                                                        </a>
                                                    </li>
                                                    <li className={`${clickedSubmenuItem === '/report/clone' ? 'clicked' : ''}`}>
                                                        <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar`} href="/report/clone">
                                                            {!sidebarOpen && <i className="ri-box-3-line icon_submenu"></i>}     
                                                            {sidebarOpen && <span id='submenu_name'>Clone</span>}
                                                        </a>
                                                    </li>
                                                    <li className={`${clickedSubmenuItem === '/report/disease' ? 'clicked' : ''}`}>
                                                        <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar`} href="/report/disease">
                                                            {!sidebarOpen && <i className="ri-bug-2-line icon_submenu"></i>}   
                                                            {sidebarOpen && <span id='submenu_name'>Disease</span>}
                                                        </a>
                                                    </li>
                                                    <li className={`${clickedSubmenuItem === '/report/fruit' ? 'clicked' : ''}`}>
                                                        <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar`} href="/report/fruit">
                                                            {!sidebarOpen && <i className="ri-plant-line icon_submenu"></i>}   
                                                            {sidebarOpen && <span id='submenu_name'>Fruit</span>}
                                                        </a>
                                                    </li>
                                                    <li className={`${clickedSubmenuItem === '/report/phase' ? 'clicked' : ''}`}>
                                                        <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar`} href="/report/phase">
                                                            {!sidebarOpen && <i className="ri-plant-line icon_submenu"></i>}   
                                                            {sidebarOpen && <span id='submenu_name'>Phase</span>}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </>
                        )}

                        {redirectUrl && (
                            <>
                                {canManagePlantation && <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar ${isPlantationListPage ? 'active' : ''}`} href={redirectUrl}>
                                    <div className='icon_sidebar text-center'>
                                        <i className="ri-plant-line"></i>
                                    </div>
                                    Plantation
                                </a>}
                            </>
                        )}
                    </div>

                </div>

            </div>

            <div className="w3-sidebar w3-bar-block w3-card w3-animate-right sidebarqr d-none" style={sidebarStyle2} id="mySidebar2">

                <div className='sidebar_headerqr'>
                    {/* <label className='bold_poppins text-black'>History</label> */}
                    <div className='sidebar_scroll2'>
                        <div className='d-flex sidebardiv_main_logo top_bar'>
                            <div className='text-center'>
                                <img
                                    src="/images/main_logo.png"
                                    className="img-fluid rounded-start sidebar_main_logo"
                                    alt="..."
                                />
                            </div>
                            SABAHEBAT
                        </div>



                        {canViewDashboard && <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar  ${isDashboardPage ? 'active' : ''}`} href="/dashboard">
                            <div className='icon_sidebar text-center'>
                                <i className="ri-dashboard-2-line"></i>
                            </div>
                            Dashboard
                        </a>}

                        {!redirectUrl && (
                            <>
                                {canManageDivision && <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar ${isDivisionPage ? 'active' : ''}`} href="/block">
                                    <div className='icon_sidebar text-center'>
                                        <i className="ri-earth-line"></i>
                                    </div>
                                    Divisions
                                </a>}

                                {canManageBlock && <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar ${isBlockPage || isSubBlockPage ? 'active' : ''} ${isSubBlockPage ? 'subActive' : ''}`} href="/block">
                                    <div className='icon_sidebar text-center'>
                                        <i className="ri-building-4-line"></i>
                                    </div>
                                    Blocks
                                </a>}

                                {canManagePlantation && <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar ${isPlantationListPage ? 'active' : ''}`} href="/plantation">
                                    <div className='icon_sidebar text-center'>
                                        <i className="ri-plant-line"></i>
                                    </div>
                                    Plantations
                                </a>}
                                {canManagePlantation && <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar ${isQRgeneratorpage ? 'active' : ''}`} href="/plantation/QRgenerator">
                                    <div className='icon_sidebar text-center'>
                                        <i className="ri-qr-code-line"></i>
                                    </div>
                                    QR Generator
                                </a>}

                                {canManageProgram && <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar ${isManageProgramPage ? 'active' : ''}`} href="/program">
                                    <div className='icon_sidebar text-center'>
                                        <i className="ri-calendar-2-line"></i>                           </div>
                                    Programs
                                </a>}

                                {canManageDisease && <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar ${isDiseasepage ? 'active' : ''}`} href="/disease">
                                    <div className='icon_sidebar text-center'>
                                        <i className="ri-bug-2-line"></i>                            </div>
                                    Diseases
                                </a>}

                                {canManageClone && <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar ${isClonepage ? 'active' : ''}`} href="/clone">
                                    <div className='icon_sidebar text-center'>
                                        <i className="ri-seedling-line"></i>                            </div>
                                    Clones
                                </a>}

                                {canManagePhase && <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar ${isPhasepage ? 'active' : ''}`} href="/phase">
                                    <div className='icon_sidebar text-center'>
                                    <i class="ri-swap-3-line"></i>                    </div>
                                    Phases
                                </a>}

                                {canManageRole && <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar ${isRoleListPage ? 'active' : ''}`} href="/role">
                                    <div className='icon_sidebar text-center'>
                                        <i className="ri-shield-user-line"></i>                           </div>
                                    Roles
                                </a>}

                                {canManageUser && <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar ${isManageUserPage ? 'active' : ''}`} href="/user">
                                    <div className='icon_sidebar text-center'>
                                        <i className="ri-user-line"></i>                           </div>
                                    Users
                                </a>}

                                {canManageReport && (
                                    <div>
                                        <a
                                            className={`text-dark regular_poppins text-decoration-none d-flex top_bar head_menu ${isMenuClicked ? 'clicked' : ''}`}
                                            href="#"
                                            onClick={toggleSubMenu}
                                        >
                                            <div className='icon_sidebar text-center'>
                                                <i className="ri-file-chart-line"></i>
                                            </div>
                                            Reports
                                        </a>
                                        {isSubMenuOpen && (
                                            <div className="submenu">
                                                <ul>
                                                    <li className={`${clickedSubmenuItem === '/report/division' ? 'clicked' : ''}`}>
                                                        <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar`} style={sidebarOpen ? { marginLeft: '20%' } : {}} href="/report/division">
                                                            {!sidebarOpen && <i className="ri-earth-line icon_submenu"></i>}
                                                            {sidebarOpen && <span id='submenu_name'>Divisions</span>}
                                                        </a>
                                                    </li>
                                                    <li className={`${clickedSubmenuItem === '/report/clone' ? 'clicked' : ''}`}>
                                                        <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar`} style={sidebarOpen ? { marginLeft: '20%' } : {}} href="/report/clone">
                                                            {!sidebarOpen && <i className="ri-box-3-line icon_submenu"></i>}     
                                                            {sidebarOpen && <span id='submenu_name'>Clone</span>}
                                                        </a>
                                                    </li>
                                                    <li className={`${clickedSubmenuItem === '/report/disease' ? 'clicked' : ''}`}>
                                                        <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar`} style={sidebarOpen ? { marginLeft: '20%' } : {}} href="/report/disease">
                                                            {!sidebarOpen && <i className="ri-bug-2-line icon_submenu"></i>}   
                                                            {sidebarOpen && <span id='submenu_name'>Disease</span>}
                                                        </a>
                                                    </li>
                                                    <li className={`${clickedSubmenuItem === '/report/fruit' ? 'clicked' : ''}`}>
                                                        <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar`} style={sidebarOpen ? { marginLeft: '20%' } : {}} href="/report/fruit">
                                                            {!sidebarOpen && <i className="ri-plant-line icon_submenu"></i>}   
                                                            {sidebarOpen && <span id='submenu_name'>Fruit</span>}
                                                        </a>
                                                    </li>
                                                    <li className={`${clickedSubmenuItem === '/report/phase' ? 'clicked' : ''}`}>
                                                        <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar`} style={sidebarOpen ? { marginLeft: '20%' } : {}} href="/report/phase">
                                                            {!sidebarOpen && <i className="ri-plant-line icon_submenu"></i>}   
                                                            {sidebarOpen && <span id='submenu_name'>Phase</span>}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </>
                        )}

                        {redirectUrl && (
                            <>
                                {canManagePlantation && <a className={`text-dark regular_poppins text-decoration-none d-flex top_bar ${isPlantationListPage ? 'active' : ''}`} href={redirectUrl}>
                                    <div className='icon_sidebar text-center'>
                                        <i className="ri-plant-line"></i>
                                    </div>
                                    Plantation
                                </a>}
                            </>
                        )}
                    </div>

                </div>

            </div>


        </div>

    );
}

export default Sidebar;
