import LayoutPage from '../layouts/base';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { API_BASE_URL, SABAHEBAT_DEV_URL } from '../../constants/ApiConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faA, faAngleDown, faCrosshairs, faPlus, faSpinner, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import SuccessModal from '../components/success-modal';
import QRCodeNew from "qrcode";


function CreatePlantationPage() {

    var title_page = 'durian plantation: key details';

    const USER_ID_KEY = 'user_id';
    const userId = localStorage.getItem(USER_ID_KEY);

    const navigate = useNavigate();

    const { subBlockId } = useParams();

    const [divisionData, setDivisionData] = useState([]);
    const [optionDivisionData, setOptionDivisionData] = useState([]);
    const [selectedDivisionId, setSelectedDivisionId] = useState('');
    const [selectedDivisionName, setSelectedDivisionName] = useState('');

    const [selectedBlockId, setSelectedBlockId] = useState('');
    const [selectedBlockName, setSelectedBlockName] = useState('');
    const [blockData, setBlockData] = useState([]);
    const [optionBlockData, setOptionBlockData] = useState([]);

    const [selectedSubBlockId, setSelectedSubBlockId] = useState('');
    const [selectedSubBlockName, setSelectedSubBlockName] = useState('');
    const [subBlockData, setSubBlockData] = useState([]);
    const [optionSubBlockData, setOptionSubBlockData] = useState([]);

    const [selectedImages, setSelectedImages] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [diseaseOptions, setDiseaseOptions] = useState([]);
    const [cloneOptions, setCloneOptions] = useState([]);
    const [phaseOptions, setPhaseOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    

    const [formData, setFormData] = useState({
        division: '',
        block: '',
        blockNo: '',
        name: '',
        plantNo: '',
        type: '',
        yearPlanted: '',
        details: '',
        longitude: '',
        latitude: '',
        features: '',
        cares: '',
        status: '',
        galeri: [],
    });

    // Fruit Statistics Table Start
    const [quantityTableData, setQuantityTableData] = useState([
        { date: '', quantity: '' }
    ]);

    const handleAddQuantityRow = () => {
        setQuantityTableData([...quantityTableData, { date: '', quantity: '' }]);
    }

    const handleDeleteQuantityRow = (index) => {
        const newQuantityData = [...quantityTableData];
        newQuantityData.splice(index, 1);
        setQuantityTableData(newQuantityData);
    };

    const handleQuantityInputChange = (e, index) => {
        const { name, value } = e.target;
        const newQuantityData = [...quantityTableData];
        newQuantityData[index][name] = value;
        setQuantityTableData(newQuantityData);
    };
    // Fruit Statistics Table End

    // Diseases Table Start
    const [diseaseTableData, setDiseaseTableData] = useState([
        { date: '', name: '' }
    ]);

    const handleAddDiseaseRow = () => {
        setDiseaseTableData([...diseaseTableData, { date: '', name: '' }]);
    }

    const handleDeleteDiseaseRow = (index) => {
        const newDiseaseData = [...diseaseTableData];
        newDiseaseData.splice(index, 1);
        setDiseaseTableData(newDiseaseData);
    };

    const handleDiseaseInputChange = (e, index) => {
        const { name, value } = e.target;
        const newDiseaseData = [...diseaseTableData];
        newDiseaseData[index][name] = value;
        setDiseaseTableData(newDiseaseData);
    };
    // Diseases Table End

    // Phases Table Start
    const [phaseTableData, setPhaseTableData] = useState([
        { date: '', name: '' }
    ]);

    const handleAddPhaseRow = () => {
        setPhaseTableData([...phaseTableData, { date: '', name: '' }]);
    }

    const handleDeletePhaseRow = (index) => {
        const newPhaseData = [...phaseTableData];
        newPhaseData.splice(index, 1);
        setPhaseTableData(newPhaseData);
    };

    const handlePhaseInputChange = (e, index) => {
        const { name, value } = e.target;
        const newPhaseData = [...phaseTableData];
        newPhaseData[index][name] = value;
        setPhaseTableData(newPhaseData);
    };
    // Phases Table End

    // Remarks Table Start
    const [remarksTableData, setRemarksTableData] = useState([
        { date: '', name: '' }
    ]);

    const handleAddRemarksRow = () => {
        setRemarksTableData([...remarksTableData, { date: '', name: '' }]);
    }

    const handleDeleteRemarksRow = (index) => {
        const newRemarksData = [...remarksTableData];
        newRemarksData.splice(index, 1);
        setRemarksTableData(newRemarksData);
    };

    const handleRemarksInputChange = (e, index) => {
        const { name, value } = e.target;
        const newRemarksData = [...remarksTableData];
        newRemarksData[index][name] = value;
        setRemarksTableData(newRemarksData);
    };
    // Remarks Table End

    useEffect(() => {
        const fetchSubBlockData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}subBlock/fetchData/${subBlockId}`);
                if (response.ok) {
                    const data = await response.json();

                    const subBlock = data.subBlock;
                    setSubBlockData(data.subBlock);
                    const block = data.block;
                    setBlockData(data.block);
                    const division = data.division;
                    setDivisionData(data.division);

                    setSubBlockData(data.subBlock);
                    setSelectedSubBlockId(subBlock.id);
                    setSelectedSubBlockName(subBlock.name);

                    // Set options for division data
                    const divisions = data.divisions;
                    setOptionDivisionData(divisions);

                    const divisionIndex = divisions.findIndex((option) => {
                        console.log("divisionId:", division.id);
                        console.log("option.id:", option.id);
                        return String(option.id) === String(division.id);
                    });

                    if (divisionIndex !== -1) {
                        setSelectedDivisionId(divisions[divisionIndex].id);
                        setSelectedDivisionName(divisions[divisionIndex].name);
                    }

                    // Set options for block data
                    const blocks = data.blocks;
                    setOptionBlockData(data.blocks);

                    const blockIndex = blocks.findIndex((option) => {
                        console.log("blockId:", block.id);
                        console.log("option.id:", option.id);
                        return String(option.id) === String(block.id);
                    });

                    if (blockIndex !== -1) {
                        setSelectedBlockId(blocks[blockIndex].id);
                        setSelectedBlockName(blocks[blockIndex].name);
                    }

                    // Set options for sub-block data
                    const subBlocks = data.subBlocks;
                    setOptionSubBlockData(data.subBlocks);

                    const subBlockIndex = subBlocks.findIndex((option) => {
                        console.log("subBlockId:", subBlock.id);
                        console.log("option.id:", option.id);
                        return String(option.id) === String(subBlock.id);
                    });

                    if (subBlockIndex !== -1) {
                        setSelectedSubBlockId(subBlocks[subBlockIndex].id);
                        setSelectedSubBlockName(subBlocks[subBlockIndex].name);
                    }
                    
                    const fetchedOptions = data.diseases;
                    setDiseaseOptions(fetchedOptions);

                    const clonesData = data.clones;
                    setCloneOptions(clonesData);

                    const phasesData = data.phases;
                    setPhaseOptions(phasesData);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchSubBlockData();
    }, [subBlockId]);
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
            block: name === 'selectedBlockId' ? value : formData.block,
        });
    };

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);

        const images = files.map((file) => ({
            file,
            preview: URL.createObjectURL(file),
        }));

        setSelectedImages([...selectedImages, ...images]);
        setFormData({
            ...formData,
            galeri: [...formData.galeri, ...files],
        });
    }

    const handleRemoveImage = (indexToRemove) => {
        const updatedImages = selectedImages.filter(
            (_, index) => index !== indexToRemove
        );
        setSelectedImages(updatedImages);

        const updatedFiles = formData.galeri.filter(
            (_, index) => index !== indexToRemove
        );
        setFormData({
            ...formData,
            galeri: updatedFiles,
        });
    }

    // Get Current Latitude & Longitude
    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            setLoading(true);
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setFormData((prevData) => ({
                        ...prevData,
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    }));
                    setLoading(false);
                },
                (error) => {
                    console.error('Error getting current location:', error);
                    setLoading(false);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }

    const handleStorePlantation = async (e) => {
        e.preventDefault();

        try {
            const formDataWithFiles = new FormData();

            formDataWithFiles.append('selectedDivisionId', selectedDivisionId);
            formDataWithFiles.append('selectedBlockId', selectedBlockId);
            formDataWithFiles.append('selectedSubBlockId', selectedSubBlockId);
            formDataWithFiles.append('userId', userId);

            Object.entries(formData).forEach(([key, value]) => {
                if (key !== 'galeri') {
                    formDataWithFiles.append(key, value);
                }
            });

            formData.galeri.forEach((file, index) => {
                formDataWithFiles.append(`galeri[${index}]`, file);
            });

            // Append fruit statistics data
            quantityTableData.forEach((row, index) => {
                formDataWithFiles.append(`quantityTableData[${index}][date]`, row.date);
                formDataWithFiles.append(`quantityTableData[${index}][quantity]`, row.quantity);
            });

            // Append diseases data
            diseaseTableData.forEach((row, index) => {
                formDataWithFiles.append(`diseaseTableData[${index}][date]`, row.date);
                formDataWithFiles.append(`diseaseTableData[${index}][name]`, row.name);
            });

            // Append phases data
            phaseTableData.forEach((row, index) => {
                formDataWithFiles.append(`phaseTableData[${index}][date]`, row.date);
                formDataWithFiles.append(`phaseTableData[${index}][name]`, row.name);
            });

            console.log(phaseTableData);

            // Append remarks data
            remarksTableData.forEach((row, index) => {
                formDataWithFiles.append(`remarksTableData[${index}][date]`, row.date);
                formDataWithFiles.append(`remarksTableData[${index}][name]`, row.name);
            });

            const response = await fetch(`${API_BASE_URL}plantation/store`, {
                method: 'POST',
                body: formDataWithFiles,
            });

            const data = await response.json();
            console.log(data)
            const plantation = data.plantation;

            if (response.status == 200) {
                // Call handleGenerateQRCode after successful plantation creation
                await handleGenerateQRCode(plantation.id);

                setShowSuccessModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    }

    const closeSuccessModal = () => {
        setShowSuccessModal(false);
        navigate(`/block/sub-block/${selectedBlockId}`);
    };

    const handleGenerateQRCode = async (plantationId) => {
        try {
            const url = `${SABAHEBAT_DEV_URL}/plantation/edit/${plantationId}?source=qrscan`;
            const redirectUrl = encodeURIComponent(url);
            const viewUrl = `${SABAHEBAT_DEV_URL}/public/plantation/view/${plantationId}?source=qrscan`;
            const redirectViewUrl = encodeURIComponent(viewUrl);
            const loginUrl = `${SABAHEBAT_DEV_URL}/?redirectUrl=${redirectUrl}&redirectViewUrl=${redirectViewUrl}`;            // Generate QR code as an HTMLCanvasElement
            const canvas = document.createElement('canvas');
            QRCodeNew.toCanvas(canvas, loginUrl);

            // Convert canvas to data URL
            const qrCodeImage = canvas.toDataURL('image/png');

            await saveQRCodeToServer(plantationId, qrCodeImage, url);
        } catch (error) {
            console.error('Error generating or saving QR code:', error);
        }
    }

    const saveQRCodeToServer = async (plantationId, dataImage, dataURL) => {
        try {
            await fetch(`${API_BASE_URL}plantation/storeQRCode/${plantationId}`, {
                method: 'POST',
                body: JSON.stringify({ dataImage, dataURL }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            console.log('QR code image sent to server:' + dataImage);
        } catch (error) {
            console.error('Error sending QR code image to server:', error);
        }
    }

    return (
        <LayoutPage>
            <div className='children'>
                <form onSubmit={handleStorePlantation}>
                    <h4 className='bold_poppins mt-3 mb-5 pg_title'>{title_page.toUpperCase()}</h4>
                    <div className="container">
                        <div className="row mb-3">
                            <div className="col-md-1 ">
                                <Form.Label className="label_control" htmlFor="basic-url">Division:</Form.Label>
                            </div>
                            <div className="col-md-5">
                                <InputGroup className="mb-3">
                                    <Form.Control as="select" id="division" name="division" aria-describedby="basic-addon3" value={formData.division || selectedDivisionId} onChange={(e) => { setSelectedDivisionId(e.target.value); handleInputChange(e); }} disabled>
                                        <option>Pilih</option>
                                        {optionDivisionData.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </InputGroup>
                            </div>
                            <div className="col-md-1 ">
                                <Form.Label className="label_control" htmlFor="basic-url">Block:</Form.Label>
                            </div>
                            <div className="col-md-5">
                                <InputGroup className="mb-3">
                                    <Form.Control as="select" id="block" name="block" aria-describedby="basic-addon3" value={formData.block || selectedBlockId} onChange={(e) => { setSelectedBlockId(e.target.value); handleInputChange(e); }} disabled>
                                        <option>Pilih</option>
                                        {optionBlockData.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                Block {option.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </InputGroup>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-1">
                                <Form.Label className="label_control" htmlFor="basic-url">Sub-Block:</Form.Label>
                            </div>
                            <div className="col-md-5">
                                <InputGroup className="mb-3">
                                    <Form.Control as="select" id="blockNo" name="blockNo" aria-describedby="basic-addon3" value={formData.blockNo || selectedSubBlockId} onChange={(e) => { setSelectedSubBlockId(e.target.value); handleInputChange(e); }} disabled>
                                        <option>Pilih</option>
                                        {optionSubBlockData.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </InputGroup>
                            </div>
                            <div className="col-md-1">
                                <Form.Label className="label_control" htmlFor="basic-url">Plant No:</Form.Label>
                            </div>
                            <div className="col-md-5">
                                <InputGroup className="mb-3">
                                    <Form.Control value={formData.plantNo} onChange={handleInputChange} name='plantNo' id="basic-url" aria-describedby="basic-addon3" />
                                </InputGroup>
                            </div>
                        </div>
                        {/* <div className="row mb-3 mt-1">
                            <div className="col-md-1 ">
                                <Form.Label className="label_control" htmlFor="basic-url">Name:</Form.Label>
                            </div>
                            <div className="col-md-11">
                                <InputGroup className="mb-3">
                                    <Form.Control value={formData.name} onChange={handleInputChange} name='name' id="basic-url" aria-describedby="basic-addon3" />
                                </InputGroup>
                            </div>
                        </div> */}
                        <div className="row mb-3">
                            <div className="col-md-1 ">
                                <Form.Label className="label_control" htmlFor="basic-url">Clone:</Form.Label>
                            </div>
                            <div className="col-md-6">
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        as="select"
                                        value={formData.type}
                                        onChange={(e) => handleInputChange(e)}
                                        name="type"
                                        required
                                    >
                                        <option value="">Select Clone</option>
                                        {cloneOptions.map((option, optionIndex) => (
                                            <option key={optionIndex} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </InputGroup>
                            </div>
                            <div className="col-md-2">
                                <Form.Label className="label_control" htmlFor="basic-url">Year Planted:</Form.Label>
                            </div>
                            <div className="col-md-3">
                                <InputGroup className="mb-3">
                                    <Form.Control value={formData.yearPlanted} onChange={handleInputChange} name='yearPlanted' id="basic-url" aria-describedby="basic-addon3" />
                                </InputGroup>
                            </div>
                        </div>
                        {/* <div className="row mb-3">
                            <div className="col-md-1 ">
                                <Form.Label className="label_control" htmlFor="basic-url">Details:</Form.Label>
                            </div>
                            <div className="col-md-11">
                                <InputGroup className="mb-3">
                                    <Form.Control as="textarea" value={formData.details} onChange={handleInputChange} name='details' id="basic-url" aria-describedby="basic-addon3" />
                                </InputGroup>
                            </div>
                        </div> */}
                        <div className="row mb-3">
                            <div className="col-md-1  ">
                                <Form.Label className="label_control" htmlFor="basic-url">Latitude:</Form.Label>
                            </div>
                            <div className="col-md-4">
                                <InputGroup className="mb-3">
                                    <Form.Control value={formData.latitude} onChange={handleInputChange} name='latitude' id="basic-url" aria-describedby="basic-addon3" />
                                </InputGroup>
                            </div>
                            <div className="col-md-1">
                                <Form.Label className="label_control" htmlFor="basic-url">Longitude:</Form.Label>
                            </div>
                            <div className="col-md-5">
                                <InputGroup className="mb-3">
                                    <Form.Control value={formData.longitude} onChange={handleInputChange} name='longitude' id="basic-url" aria-describedby="basic-addon3" />
                                </InputGroup>
                            </div>
                            <div className="col-md-1">
                                <Button variant="primary" onClick={getCurrentLocation} disabled={loading}>
                                    {loading ? <FontAwesomeIcon className="icon" icon={faSpinner} spin /> : <FontAwesomeIcon className="icon" icon={faCrosshairs} />}
                                </Button>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-1">
                                <Form.Label className="label_control" htmlFor="basic-url">Gallery:</Form.Label>
                            </div>
                            <div className="col-md-11">
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="file"
                                        multiple
                                        onChange={handleImageChange}
                                        name="galeri"
                                        id='galeri'
                                        aria-describedby='basic-addon3'
                                    />
                                </InputGroup>
                            </div>
                        </div>

                        <div className='row mb-3'>
                            <div className="col-md-1">
                            </div>
                            <div className="col-md-11 image-container">
                                {selectedImages.map((image, index) => (
                                    <div key={index} className='preview-container'>
                                        <img
                                            src={image.preview}
                                            alt={`Preview-${index}`}
                                            className='preview-image'
                                        />
                                        <button
                                            onClick={() => handleRemoveImage(index)}
                                            className="remove-button"
                                        >
                                            <FontAwesomeIcon icon={faTimes} />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>


                        {/* <div className="row mb-3">
                            <div className="col-md-1 ">
                                <Form.Label className="label_control" htmlFor="basic-url">Features:</Form.Label>
                            </div>
                            <div className="col-md-11">
                                <InputGroup className="mb-3">
                                    <Form.Control as="textarea" value={formData.features} name='features' onChange={handleInputChange} id="basic-url" aria-describedby="basic-addon3" />
                                </InputGroup>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-1  ">
                                <Form.Label className="label_control" htmlFor="basic-url">Care:</Form.Label>
                            </div>
                            <div className="col-md-11">
                                <InputGroup className="mb-3">
                                    <Form.Control as="textarea" value={formData.cares} name='cares' onChange={handleInputChange} id="basic-url" aria-describedby="basic-addon3" />
                                </InputGroup>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-1  ">
                                <Form.Label className="label_control" htmlFor="basic-url">Status:</Form.Label>
                            </div>
                            <div className="col-md-11">
                                <InputGroup className="mb-3">
                                    <Form.Control as="textarea" value={formData.status} name='status' onChange={handleInputChange} id="basic-url" aria-describedby="basic-addon3" />
                                </InputGroup>
                            </div>
                        </div> */}
                        <div className="row mb-3">
                            <div className="col-md-12 d-flex justify-content-between">
                                <Form.Label className="label_control" htmlFor="basic-url">Fruit Statistics:</Form.Label>
                                <Button variant="primary" onClick={handleAddQuantityRow}><FontAwesomeIcon className="icon" icon={faPlus} />Add New</Button>
                            </div>
                            <div className="col-md-11 mt-2 ml-auto table-container table_plantation">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Date</th>
                                            <th>Quantity</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {quantityTableData.map((rowData, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control type="date" value={rowData.date} onChange={(e) => handleQuantityInputChange(e, index)} name="date" id="basic-url" aria-describedby="basic-addon3" />
                                                    </InputGroup>
                                                </td>
                                                <td>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control type="number" value={rowData.quantity} onChange={(e) => handleQuantityInputChange(e, index)} name="quantity" id="basic-url" aria-describedby="basic-addon3" />
                                                    </InputGroup>
                                                </td>
                                                <td>
                                                    <FontAwesomeIcon onClick={() => handleDeleteQuantityRow(index)} icon={faTrash} className="icon delete-icon" />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-12 d-flex justify-content-between">
                                <Form.Label className="label_control" htmlFor="basic-url">Diseases:</Form.Label>
                                <Button variant="primary" onClick={handleAddDiseaseRow}><FontAwesomeIcon className="icon" icon={faPlus} />Add New</Button>
                            </div>
                            <div className="col-md-11 mt-2 ml-auto table-container table_plantation">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Date</th>
                                            <th>Diseases Name</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {diseaseTableData.map((rowData, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control type="date" value={rowData.date} onChange={(e) => handleDiseaseInputChange(e, index)} name="date" id="basic-url" aria-describedby="basic-addon3" />
                                                    </InputGroup>
                                                </td>
                                                <td>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control
                                                            as="select" // Use 'select' as the input type
                                                            value={rowData.name}
                                                            onChange={(e) => handleDiseaseInputChange(e, index)}
                                                            name="name"
                                                        >
                                                            <option value="">Select Disease</option>
                                                            {diseaseOptions.map((option, optionIndex) => (
                                                                <option key={optionIndex} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </Form.Control>
                                                    </InputGroup>
                                                </td>
                                                <td>
                                                    <FontAwesomeIcon onClick={() => handleDeleteDiseaseRow(index)} icon={faTrash} className="icon delete-icon" />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-12 d-flex justify-content-between">
                                <Form.Label className="label_control" htmlFor="basic-url">Phases:</Form.Label>
                                <Button variant="primary" onClick={handleAddPhaseRow}><FontAwesomeIcon className="icon" icon={faPlus} />Add New</Button>
                            </div>
                            <div className="col-md-11 mt-2 ml-auto table-container table_plantation">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Date</th>
                                            <th>Phases</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {phaseTableData.map((rowData, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control type="date" value={rowData.date} onChange={(e) => handlePhaseInputChange(e, index)} name="date" id="basic-url" aria-describedby="basic-addon3" />
                                                    </InputGroup>
                                                </td>
                                                <td>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control
                                                            as="select" // Use 'select' as the input type
                                                            value={rowData.name}
                                                            onChange={(e) => handlePhaseInputChange(e, index)}
                                                            name="name"
                                                        >
                                                            <option value="">Select Phase</option>
                                                            {phaseOptions.map((option, optionIndex) => (
                                                                <option key={optionIndex} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </Form.Control>
                                                    </InputGroup>
                                                </td>
                                                <td>
                                                    <FontAwesomeIcon onClick={() => handleDeletePhaseRow(index)} icon={faTrash} className="icon delete-icon" />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-12 d-flex justify-content-between">
                                <Form.Label className="label_control" htmlFor="basic-url">Remarks:</Form.Label>
                                <Button variant="primary" onClick={handleAddRemarksRow}><FontAwesomeIcon className="icon" icon={faPlus} />Add New</Button>
                            </div>
                            <div className="col-md-11 mt-2 ml-auto table-container table_plantation">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Date</th>
                                            <th>Remarks</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {remarksTableData.map((rowData, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control type="date" value={rowData.date} onChange={(e) => handleRemarksInputChange(e, index)} name="date" id="basic-url" aria-describedby="basic-addon3" />
                                                    </InputGroup>
                                                </td>
                                                <td>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control type="text" value={rowData.name} onChange={(e) => handleRemarksInputChange(e, index)} name="name" id="basic-url" aria-describedby="basic-addon3" />
                                                    </InputGroup>
                                                </td>
                                                <td>
                                                    <FontAwesomeIcon onClick={() => handleDeleteRemarksRow(index)} icon={faTrash} className="icon delete-icon" />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className=' mt-5 mb-5  text-center'>
                            <Button className='btn formSimpanBtn' type='submit'>Submit</Button>
                            <button className='btn formBatalBtn' onClick={() => navigate(`/plantation`)}>Cancel</button>
                        </div>

                    </div>
                </form>

                {/* Modal/Popup for Success Message */}
                {showSuccessModal && (
                    <SuccessModal
                        show={showSuccessModal}
                        onHide={closeSuccessModal}
                        title="Success"
                        modalBody={<p>Data successfully saved!</p>}
                        buttonText="Close"
                    />
                )}
            </div>

        </LayoutPage>
    )
}

export default CreatePlantationPage