import LayoutPage from '../layouts/base';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { Button, Modal } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { API_BASE_URL, IMAGE_BASE_URL } from '../../constants/ApiConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faA, faAngleDown, faCrosshairs, faPlus, faSpinner, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import SuccessModal from '../components/success-modal';
import { useAuth } from '../../services/AuthProvider';


function EditPlantationPage() {

    const { user, hasPermission } = useAuth();

    const canManagePlantation = hasPermission('manage-plantation');
    const canCreatePlantation = hasPermission('create-plantation');
    const canEditPlantation = hasPermission('edit-plantation');
    const canDeletePlantation = hasPermission('delete-plantation');

    var title_page = 'update durian plantation: key details';

    const USER_ID_KEY = 'user_id';
    const userId = localStorage.getItem(USER_ID_KEY);
    const redirectUrl = localStorage.getItem('redirectUrl');

    const navigate = useNavigate();

    const { plantationId } = useParams();
    const [subBlockData, setSubBlockData] = useState([]);
    const [blockData, setBlockData] = useState([]);
    const [divisionData, setDivisionData] = useState([]);
    const [selectedSubBlockId, setSelectedSubBlockId] = useState('');
    const [selectedBlockId, setSelectedBlockId] = useState('');
    const [selectedDivisionId, setSelectedDivisionId] = useState('');
    const [plantationData, setPlantationData] = useState([]);
    const [plantationImages, setPlantationImages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [statisticsData, setStatisticsData] = useState([]);
    const [quantityTableData, setQuantityTableData] = useState([]);
    const [diseasesData, setDiseasesData] = useState([]);
    const [diseaseTableData, setDiseaseTableData] = useState([]);
    const [phasesData, setPhasesData] = useState([]);
    const [phaseTableData, setPhaseTableData] = useState([]);
    const [remarksData, setRemarksData] = useState([]);
    const [remarksTableData, setRemarksTableData] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [diseaseOptions, setDiseaseOptions] = useState([]);
    const [cloneOptions, setCloneOptions] = useState([]);
    const [phaseOptions, setPhaseOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        division: '',
        block: '',
        blockNo: '',
        name: '',
        plantNo: '',
        type: '',
        yearPlanted: '',
        details: '',
        longitude: '',
        latitude: '',
        features: '',
        cares: '',
        status: '',
        galeri: [],
    });

    useEffect(() => {
        const fetchPlantationData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}plantation/edit/${plantationId}`);
                if (response.ok) {
                    const data = await response.json();
                    console.log(data)

                    setSubBlockData(data.all_subBlocks);
                    setBlockData(data.all_blocks);
                    setDivisionData(data.all_divisions);

                    const subBlock = data.subBlock;
                    setSelectedSubBlockId(subBlock.id);

                    const block = data.block;
                    setSelectedBlockId(block.id);

                    const division = data.division;
                    setSelectedDivisionId(division.id);

                    const fetchedOptions = data.diseases;
                    setDiseaseOptions(fetchedOptions);

                    const clonesData = data.clones;
                    setCloneOptions(clonesData);

                    const phasesData = data.phases;
                    setPhaseOptions(phasesData);

                    setPlantationData(data.plantation);
                    if (data.plantation && data.plantation.plant_images) {
                        setPlantationImages(data.plantation.plant_images);
                    }
                    setStatisticsData(data.plant_statistics);
                    setDiseasesData(data.plant_diseases);
                    setRemarksData(data.plant_remarks);
                    setPhasesData(data.plant_phases);
                }
            } catch (error) {
                console.error('Error fetching zone data:', error);
            }
        };

        fetchPlantationData();
    }, [plantationId]);

    useEffect(() => {
        if (plantationData && Object.keys(plantationData).length > 0) {
            setFormData({
                blockNo: plantationData.block_no || '',
                name: plantationData.name || '',
                plantNo: plantationData.plant_no || '',
                type: plantationData.clone_id || 0,
                yearPlanted: plantationData.year_planted || '',
                details: plantationData.details || '',
                longitude: plantationData.longitude || '',
                latitude: plantationData.latitude || '',
                features: plantationData.features || '',
                cares: plantationData.cares || '',
                status: plantationData.status || '',
                galeri: [],
            });
        }
    }, [plantationData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Fruit Statistics Table Start
    useEffect(() => {
        const formattedData = statisticsData.map(item => ({
            ...item,
            date: item.date_recorded ? item.date_recorded : '' // Assuming the date field is 'date_recorded'
        }));
        setQuantityTableData([...formattedData]);
    }, [statisticsData]);

    const handleAddQuantityRow = () => {
        setQuantityTableData([...quantityTableData, { date: '', quantity: '' }]);
    }

    const handleDeleteQuantityRow = (index) => {
        const newQuantityData = [...quantityTableData];
        newQuantityData.splice(index, 1);
        setQuantityTableData(newQuantityData);
    };

    const handleQuantityInputChange = (e, index) => {
        const { name, value } = e.target;
        const newQuantityData = [...quantityTableData];
        newQuantityData[index][name] = value;
        setQuantityTableData(newQuantityData);
    };
    // Fruit Statistics Table End

    // Diseases Table Start
    useEffect(() => {
        const formattedData = diseasesData.map(item => ({
            ...item,
            date: item.date_recorded ? item.date_recorded : ''
        }));
        setDiseaseTableData([...formattedData]);
    }, [diseasesData]);


    const handleAddDiseaseRow = () => {
        setDiseaseTableData([...diseaseTableData, { date: '', name: '' }]);
    }

    const handleDeleteDiseaseRow = (index) => {
        const newDiseaseData = [...diseaseTableData];
        newDiseaseData.splice(index, 1);
        setDiseaseTableData(newDiseaseData);
    };

    const handleDiseaseInputChange = (e, index) => {
        const { name, value } = e.target;
        const newDiseaseData = [...diseaseTableData];
        newDiseaseData[index][name] = value;
        setDiseaseTableData(newDiseaseData);
    };
    // Diseases Table End

    // Phases Table Start
    useEffect(() => {
        const formattedData = phasesData.map(item => ({
            ...item,
            date: item.date_recorded ? item.date_recorded : ''
        }));
        setPhaseTableData([...formattedData]);
    }, [phasesData]);


    const handleAddPhaseRow = () => {
        setPhaseTableData([...phaseTableData, { date: '', name: '' }]);
    }

    const handleDeletePhaseRow = (index) => {
        const newPhaseData = [...phaseTableData];
        newPhaseData.splice(index, 1);
        setPhaseTableData(newPhaseData);
    };

    const handlePhaseInputChange = (e, index) => {
        const { name, value } = e.target;
        const newPhaseData = [...phaseTableData];
        newPhaseData[index][name] = value;
        setPhaseTableData(newPhaseData);
    };
    // Phases Table End

    // Remarks Table Start
    useEffect(() => {
        const formattedData = remarksData.map(item => ({
            ...item,
            date: item.date_recorded ? item.date_recorded : '' // Assuming the date field is 'date_recorded'
        }));
        setRemarksTableData([...formattedData]);
    }, [remarksData]);


    const handleAddRemarksRow = () => {
        setRemarksTableData([...remarksTableData, { date: '', name: '' }]);
    }

    const handleDeleteRemarksRow = (index) => {
        const newRemarksData = [...remarksTableData];
        newRemarksData.splice(index, 1);
        setRemarksTableData(newRemarksData);
    };

    const handleRemarksInputChange = (e, index) => {
        const { name, value } = e.target;
        const newRemarksData = [...remarksTableData];
        newRemarksData[index][name] = value;
        setRemarksTableData(newRemarksData);
    };
    // Remarks Table End

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);

        const images = files.map((file) => ({
            file,
            preview: URL.createObjectURL(file),
        }));

        setSelectedImages([...selectedImages, ...images]);
        setFormData({
            ...formData,
            galeri: [...formData.galeri, ...files],
        });
    }

    const handleRemoveImage = (indexToRemove) => {
        const updatedImages = selectedImages.filter(
            (_, index) => index !== indexToRemove
        );
        setSelectedImages(updatedImages);

        const updatedFiles = formData.galeri.filter(
            (_, index) => index !== indexToRemove
        );
        setFormData({
            ...formData,
            galeri: updatedFiles,
        });
    }

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [showSuccessDeleteModal, setshowSuccessDeleteModal] = useState(false);

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setDeleteIndex(null);
    };

    const handleConfirmDelete = (id) => {
        setDeleteIndex(id);
        setShowDeleteModal(true);
    };

    const closeSuccessModal = () => {
        setShowSuccessModal(false);
        window.location.reload();
    };

    const handleRemoveExistImage = async (index) => {
        try {
            const response = await fetch(`${API_BASE_URL}plantation/removeImage/${index}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                setShowDeleteModal(false);
                setshowSuccessDeleteModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    }

    // Get Current Latitude & Longitude
    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            setLoading(true);
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setFormData((prevData) => ({
                        ...prevData,
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    }));
                    setLoading(false);
                },
                (error) => {
                    console.error('Error getting current location:', error);
                    setLoading(false);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }

    const handleUpdatePlantation = async (e) => {
        e.preventDefault();

        if (!showDeleteModal) {
            try {
                const formDataWithFiles = new FormData();

                formDataWithFiles.append('userId', userId);

                Object.entries(formData).forEach(([key, value]) => {
                    if (key !== 'galeri') {
                        formDataWithFiles.append(key, value);
                    }
                });

                if (formData.galeri && formData.galeri.length > 0) {
                    formData.galeri.forEach((file, index) => {
                        formDataWithFiles.append(`galeri[${index}]`, file);
                    });
                }

                // Append fruit statistics data
                const existingQuantityData = quantityTableData.filter(item => item.id);
                const newQuantityData = quantityTableData.filter(item => !item.id);

                existingQuantityData.forEach((row, index) => {
                    formDataWithFiles.append(`existingQuantityTableData[${index}][id]`, row.id);
                    formDataWithFiles.append(`existingQuantityTableData[${index}][date]`, row.date);
                    formDataWithFiles.append(`existingQuantityTableData[${index}][quantity]`, row.quantity);
                });

                newQuantityData.forEach((row, index) => {
                    formDataWithFiles.append(`newQuantityTableData[${index}][date]`, row.date);
                    formDataWithFiles.append(`newQuantityTableData[${index}][quantity]`, row.quantity);
                });

                // Append diseases data
                const existingDiseaseData = diseaseTableData.filter(item => item.id);
                const newDiseaseData = diseaseTableData.filter(item => !item.id);

                existingDiseaseData.forEach((row, index) => {
                    formDataWithFiles.append(`existingDiseaseTableData[${index}][id]`, row.id);
                    formDataWithFiles.append(`existingDiseaseTableData[${index}][date]`, row.date);
                    formDataWithFiles.append(`existingDiseaseTableData[${index}][name]`, row.disease_id);
                });

                newDiseaseData.forEach((row, index) => {
                    formDataWithFiles.append(`newDiseaseTableData[${index}][date]`, row.date);
                    formDataWithFiles.append(`newDiseaseTableData[${index}][name]`, row.name);
                });

                // Append phases data
                const existingPhaseData = phaseTableData.filter(item => item.id);
                const newPhaseData = phaseTableData.filter(item => !item.id);

                existingPhaseData.forEach((row, index) => {
                    formDataWithFiles.append(`existingPhaseTableData[${index}][id]`, row.id);
                    formDataWithFiles.append(`existingPhaseTableData[${index}][date]`, row.date);
                    formDataWithFiles.append(`existingPhaseTableData[${index}][name]`, row.phase_id);
                });

                newPhaseData.forEach((row, index) => {
                    formDataWithFiles.append(`newPhaseTableData[${index}][date]`, row.date);
                    formDataWithFiles.append(`newPhaseTableData[${index}][name]`, row.name);
                });

                // Append remarks data
                const existingRemarkData = remarksTableData.filter(item => item.id);
                const newRemarkData = remarksTableData.filter(item => !item.id);

                existingRemarkData.forEach((row, index) => {
                    formDataWithFiles.append(`existingRemarkTableData[${index}][id]`, row.id);
                    formDataWithFiles.append(`existingRemarkTableData[${index}][date]`, row.date);
                    formDataWithFiles.append(`existingRemarkTableData[${index}][name]`, row.name);
                });

                newRemarkData.forEach((row, index) => {
                    formDataWithFiles.append(`newRemarksTableData[${index}][date]`, row.date);
                    formDataWithFiles.append(`newRemarksTableData[${index}][name]`, row.name);
                });

                const response = await fetch(`${API_BASE_URL}plantation/update/${plantationId}`, {
                    method: 'POST',
                    body: formDataWithFiles,
                });

                const data = await response.json();
                console.log(data)

                if (response.status == 200) {
                    setShowSuccessModal(true);
                }

            } catch (error) {
                // Handle fetch errors
                console.error('Error:', error);
            }
        }
    }

    return (
        <LayoutPage>
            <div className='children'>
                <form onSubmit={handleUpdatePlantation}>
                    <h4 className='bold_poppins mt-3 mb-5 pg_title'>{title_page.toUpperCase()}</h4>
                    <div className="container pb-5">
                        <div className="row mb-3">
                            <div className="col-md-1 ">
                                <Form.Label className="label_control" htmlFor="basic-url">Division:</Form.Label>
                            </div>
                            <div className="col-md-5">
                                <InputGroup className="mb-3">
                                    <Form.Control as="select" id="division" name="division" aria-describedby="basic-addon3" value={formData.division || selectedDivisionId} onChange={(e) => { setSelectedDivisionId(e.target.value); handleInputChange(e); }} disabled>
                                        <option>Pilih</option>
                                        {divisionData.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </InputGroup>
                            </div>
                            <div className="col-md-1 ">
                                <Form.Label className="label_control" htmlFor="basic-url">Block:</Form.Label>
                            </div>
                            <div className="col-md-5">
                                <InputGroup className="mb-3">
                                    <Form.Control as="select" id="block" name="block" aria-describedby="basic-addon3" value={formData.block || selectedBlockId} onChange={(e) => { setSelectedBlockId(e.target.value); handleInputChange(e); }} disabled>
                                        <option>Pilih</option>
                                        {blockData.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                Block {option.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </InputGroup>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-1">
                                <Form.Label className="label_control" htmlFor="basic-url">Sub-Block:</Form.Label>
                            </div>
                            <div className="col-md-5">
                                <InputGroup className="mb-3">
                                    <Form.Control as="select" id="blockNo" name="blockNo" aria-describedby="basic-addon3" value={formData.blockNo || selectedSubBlockId} onChange={(e) => { setSelectedSubBlockId(e.target.value); handleInputChange(e); }} disabled>
                                        <option>Pilih</option>
                                        {subBlockData.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </InputGroup>
                            </div>
                            <div className="col-md-1">
                                <Form.Label className="label_control" htmlFor="basic-url">Plant No:</Form.Label>
                            </div>
                            <div className="col-md-5">
                                <InputGroup className="mb-3">
                                    <Form.Control value={formData.plantNo} onChange={handleInputChange} name='plantNo' id="basic-url" aria-describedby="basic-addon3" />
                                </InputGroup>
                            </div>
                        </div>
                        {/* <div className="row mb-3 mt-1">
                            <div className="col-md-1 ">
                                <Form.Label className="label_control" htmlFor="basic-url">Name:</Form.Label>
                            </div>
                            <div className="col-md-11">
                                <InputGroup className="mb-3">
                                    <Form.Control value={formData.name} onChange={handleInputChange} name='name' id="basic-url" aria-describedby="basic-addon3" />
                                </InputGroup>
                            </div>
                        </div> */}
                        <div className="row mb-3">
                            <div className="col-md-1 ">
                                <Form.Label className="label_control" htmlFor="basic-url">Clone:</Form.Label>
                            </div>
                            <div className="col-md-6">
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        as="select" // Use 'select' as the input type
                                        value={formData.type}
                                        onChange={(e) => handleInputChange(e)}
                                        name="type"
                                    >
                                        <option value="">Select Clone</option>
                                        {cloneOptions.map((option, optionIndex) => (
                                            <option key={optionIndex} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </InputGroup>
                            </div>
                            <div className="col-md-2">
                                <Form.Label className="label_control" htmlFor="basic-url">Year Planted:</Form.Label>
                            </div>
                            <div className="col-md-3">
                                <InputGroup className="mb-3">
                                    <Form.Control value={formData.yearPlanted} onChange={handleInputChange} name='yearPlanted' id="basic-url" aria-describedby="basic-addon3" />
                                </InputGroup>
                            </div>
                        </div>
                        {/* <div className="row mb-3">
                            <div className="col-md-1 ">
                                <Form.Label className="label_control" htmlFor="basic-url">Details:</Form.Label>
                            </div>
                            <div className="col-md-11">
                                <InputGroup className="mb-3">
                                    <Form.Control as="textarea" value={formData.details} onChange={handleInputChange} name='details' id="basic-url" aria-describedby="basic-addon3" />
                                </InputGroup>
                            </div>
                        </div> */}
                        <div className="row mb-3">
                            <div className="col-md-1  ">
                                <Form.Label className="label_control" htmlFor="basic-url">Latitude:</Form.Label>
                            </div>
                            <div className="col-md-4">
                                <InputGroup className="mb-3">
                                    <Form.Control value={formData.latitude} onChange={handleInputChange} name='latitude' id="basic-url" aria-describedby="basic-addon3" />
                                </InputGroup>
                            </div>
                            <div className="col-md-1 ">
                                <Form.Label className="label_control" htmlFor="basic-url">Longitude:</Form.Label>
                            </div>
                            <div className="col-md-5">
                                <InputGroup className="mb-3">
                                    <Form.Control value={formData.longitude} onChange={handleInputChange} name='longitude' id="basic-url" aria-describedby="basic-addon3" />
                                </InputGroup>
                            </div>
                            <div className="col-md-1">
                                <Button
                                    variant="primary"
                                    onClick={getCurrentLocation}
                                    disabled={loading}
                                >
                                    {loading ? <FontAwesomeIcon className="icon" icon={faSpinner} spin /> : <FontAwesomeIcon className="icon" icon={faCrosshairs} />}
                                </Button>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-1">
                                <Form.Label className="label_control" htmlFor="basic-url">Gallery:</Form.Label>
                            </div>
                            <div className="col-md-11">
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="file"
                                        multiple
                                        onChange={handleImageChange}
                                        name="galeri"
                                        id='galeri'
                                        aria-describedby='basic-addon3'
                                    />
                                </InputGroup>
                            </div>
                        </div>

                        <div className='row mb-3'>
                            <div className="col-md-1">
                            </div>
                            <div className="col-md-11 image-container">
                                {selectedImages.map((image, index) => (
                                    <div key={index} className='preview-container'>
                                        <img
                                            src={image.preview}
                                            alt={`Preview-${index}`}
                                            className='preview-image'
                                        />
                                        <button
                                            onClick={() => handleRemoveImage(index)}
                                            className="remove-button"
                                        >
                                            <FontAwesomeIcon icon={faTimes} />
                                        </button>
                                    </div>
                                ))}
                                {plantationImages.map((image, index) => (
                                    <div key={index} className='preview-container'>
                                        <img
                                            src={`${IMAGE_BASE_URL}${image.name}`}
                                            alt={`Preview-${index}`}
                                            className='preview-image'
                                        />
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleConfirmDelete(image.id);
                                            }}
                                            className="remove-button"
                                        >
                                            <FontAwesomeIcon icon={faTimes} />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>


                        {/* <div className="row mb-3">
                            <div className="col-md-1 ">
                                <Form.Label className="label_control" htmlFor="basic-url">Features:</Form.Label>
                            </div>
                            <div className="col-md-11">
                                <InputGroup className="mb-3">
                                    <Form.Control as="textarea" value={formData.features} name='features' onChange={handleInputChange} id="basic-url" aria-describedby="basic-addon3" />
                                </InputGroup>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-1  ">
                                <Form.Label className="label_control" htmlFor="basic-url">Care:</Form.Label>
                            </div>
                            <div className="col-md-11">
                                <InputGroup className="mb-3">
                                    <Form.Control as="textarea" value={formData.cares} name='cares' onChange={handleInputChange} id="basic-url" aria-describedby="basic-addon3" />
                                </InputGroup>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-1  ">
                                <Form.Label className="label_control" htmlFor="basic-url">Status:</Form.Label>
                            </div>
                            <div className="col-md-11">
                                <InputGroup className="mb-3">
                                    <Form.Control as="textarea" value={formData.status} name='status' onChange={handleInputChange} id="basic-url" aria-describedby="basic-addon3" />
                                </InputGroup>
                            </div>
                        </div> */}
                        <div className="row mb-3">
                            <div className="col-md-12 d-flex justify-content-between">
                                <Form.Label className="label_control" htmlFor="basic-url">Fruit Statistics:</Form.Label>
                                <Button variant="primary" onClick={handleAddQuantityRow}><FontAwesomeIcon className="icon" icon={faPlus} />Add New</Button>
                            </div>
                            <div className="col-md-11 mt-2 ml-auto table-container table_plantation">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Date</th>
                                            <th>Quantity</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {quantityTableData.map((rowData, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control
                                                            type="date"
                                                            value={rowData.date}
                                                            onChange={(e) => handleQuantityInputChange(e, index)}
                                                            name="date"
                                                            id={`date-${index}`}
                                                            aria-describedby={`date-${index}`}
                                                        />
                                                    </InputGroup>
                                                </td>
                                                <td>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control type="number" value={rowData.quantity} onChange={(e) => handleQuantityInputChange(e, index)} name="quantity" id="basic-url" aria-describedby="basic-addon3" />
                                                    </InputGroup>
                                                </td>
                                                <td>
                                                    <FontAwesomeIcon onClick={() => handleDeleteQuantityRow(index)} icon={faTrash} className="icon delete-icon" />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-12 d-flex justify-content-between">
                                <Form.Label className="label_control" htmlFor="basic-url">Diseases:</Form.Label>
                                <Button variant="primary" onClick={handleAddDiseaseRow}><FontAwesomeIcon className="icon" icon={faPlus} />Add New</Button>
                            </div>
                            <div className="col-md-11 mt-2 ml-auto table-container table_plantation">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Date</th>
                                            <th>Diseases Name</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {diseaseTableData.map((rowData, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control
                                                            type="date"
                                                            value={rowData.date}
                                                            onChange={(e) => handleDiseaseInputChange(e, index)}
                                                            name="date"
                                                            id={`date-${index}`}
                                                            aria-describedby={`date-${index}`}
                                                        />
                                                    </InputGroup>
                                                </td>
                                                <td>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control
                                                            as="select"
                                                            value={rowData.disease_id}
                                                            onChange={(e) => handleDiseaseInputChange(e, index)}
                                                            name="name"
                                                        >
                                                            <option value="">Select Disease</option>
                                                            {diseaseOptions.map((option, optionIndex) => (
                                                                <option key={optionIndex} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </Form.Control>
                                                    </InputGroup>
                                                </td>
                                                <td>
                                                    <FontAwesomeIcon onClick={() => handleDeleteDiseaseRow(index)} icon={faTrash} className="icon delete-icon" />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-12 d-flex justify-content-between">
                                <Form.Label className="label_control" htmlFor="basic-url">Phases:</Form.Label>
                                <Button variant="primary" onClick={handleAddPhaseRow}><FontAwesomeIcon className="icon" icon={faPlus} />Add New</Button>
                            </div>
                            <div className="col-md-11 mt-2 ml-auto table-container table_plantation">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Date</th>
                                            <th>Phase</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {phaseTableData.map((rowData, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control
                                                            type="date"
                                                            value={rowData.date}
                                                            onChange={(e) => handlePhaseInputChange(e, index)}
                                                            name="date"
                                                            id={`date-${index}`}
                                                            aria-describedby={`date-${index}`}
                                                        />
                                                    </InputGroup>
                                                </td>
                                                <td>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control
                                                            as="select"
                                                            value={rowData.phase_id}
                                                            onChange={(e) => handlePhaseInputChange(e, index)}
                                                            name="name"
                                                        >
                                                            <option value="">Select Phase</option>
                                                            {phaseOptions.map((option, optionIndex) => (
                                                                <option key={optionIndex} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </Form.Control>
                                                    </InputGroup>
                                                </td>
                                                <td>
                                                    <FontAwesomeIcon onClick={() => handleDeletePhaseRow(index)} icon={faTrash} className="icon delete-icon" />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-12 d-flex justify-content-between">
                                <Form.Label className="label_control" htmlFor="basic-url">Remarks:</Form.Label>
                                <Button variant="primary" onClick={handleAddRemarksRow}><FontAwesomeIcon className="icon" icon={faPlus} />Add New</Button>
                            </div>
                            <div className="col-md-11 mt-2 ml-auto table-container table_plantation">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Date</th>
                                            <th>Remarks</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {remarksTableData.map((rowData, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control
                                                            type="date"
                                                            value={rowData.date}
                                                            onChange={(e) => handleRemarksInputChange(e, index)}
                                                            name="date"
                                                            id={`date-${index}`}
                                                            aria-describedby={`date-${index}`}
                                                        />
                                                    </InputGroup>
                                                </td>
                                                <td>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control type="text" value={rowData.name} onChange={(e) => handleRemarksInputChange(e, index)} name="name" id="basic-url" aria-describedby="basic-addon3" />
                                                    </InputGroup>
                                                </td>
                                                <td>
                                                    <FontAwesomeIcon onClick={() => handleDeleteRemarksRow(index)} icon={faTrash} className="icon delete-icon" />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className='mt-3 text-center' style={{ paddingBottom: '30%' }}>
                            {canEditPlantation && <Button className='btn formSimpanBtn' type='submit'>Update</Button>}
                            {!redirectUrl && (
                                <Button className='btn formBatalBtn' onClick={() => navigate(`/plantation`)}>Cancel</Button>
                            )}
                            {(canManagePlantation && redirectUrl) && (
                                <button className='btn formUpdateBtn' onClick={() => navigate(`/plantation/view/${plantationId}`)}>View</button>
                            )}
                        </div>
                    </div>
                </form>

                {/* Modal/Popup for Success Message */}
                {showSuccessModal && (
                    <SuccessModal
                        show={showSuccessModal}
                        onHide={closeSuccessModal}
                        title="Success"
                        modalBody={<p>Data successfully updated!</p>}
                        buttonText="Close"
                    />
                )}

                {/* Delete Modal */}
                <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure want to delete this image?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleCloseDeleteModal}>
                            Cancel
                        </Button>
                        <Button className="delete-button" onClick={() => handleRemoveExistImage(deleteIndex)}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Modal/Popup for Confirmation Delete */}
                {showSuccessDeleteModal && (
                    <SuccessModal
                        show={showSuccessDeleteModal}
                        onHide={closeSuccessModal}
                        title="Success"
                        modalBody={<p>Image has been successfully deleted!</p>}
                        buttonText="OK"
                    />
                )}
            </div>

        </LayoutPage>
    )
}

export default EditPlantationPage