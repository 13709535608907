// src/utils/colorUtils.js

// Function to generate random colors
export const generateRandomColors = (count) => {
    const backgroundColors = [];
    const hueStep = 360 / count; // Calculate the step for distributing hues evenly
    for (let i = 0; i < count; i++) {
        const hue = (hueStep * i) % 360; // Generate hue based on step
        const color = getRandomColor(hue);
        backgroundColors.push(color);
    }
    return backgroundColors;
};

// Function to generate a random color with specific hue
export const getRandomColor = (hue) => {
    const s = 80; // Fixed saturation for vibrant colors
    const l = 50; // Fixed lightness for consistent brightness

    return `hsl(${hue}, ${s}%, ${l}%)`;
};

