// import LayoutPage from './Base';
import LayoutPage from '../layouts/base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencil, faTrash, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { API_BASE_URL } from '../../constants/ApiConfig';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import SuccessModal from "../components/success-modal";
import { useAuth } from '../../services/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



function BlockListingPage() {

    const { user, hasPermission } = useAuth();
    const navigate = useNavigate();

    const canManageBlock = hasPermission('manage-block');
    const canCreateBlock = hasPermission('create-block');
    const canEditBlock = hasPermission('edit-block');
    const canDeleteBlock = hasPermission('delete-block');

    const itemsPerPage = 10;

    const [blockData, setBlockData] = useState([]);
    const [divisionData, setDivisionData] = useState([]);
    const [selectedDivision, setSelectedDivision] = useState('');
    const handleBlockModal = () => setShowModal(true);
    const [blockName, setBlockName] = useState('');
    const handleModalClose = () => setShowModal(false);
    const [showModal, setShowModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [editBlockName, setEditBlockName] = useState('');
    const [editBlockId, setEditBlockId] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showSuccessDeleteModal, setshowSuccessDeleteModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);

    const [selectedDivisionId, setSelectedDivisionId] = useState('');

    useEffect(() => {
        const fetchBlockData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}block/index`);
                if (response.ok) {
                    const data = await response.json();
                    setBlockData(data.blocks);
                    setDivisionData(data.divisions);
                }
            } catch (error) {
                console.error('Error fetching zone data:', error);
            }
        };

        fetchBlockData();
    }, []);

    const handleNameChange = (e) => {
        setBlockName(e.target.value);
    };

    const handleDivisionInputChange = (e) => {
        setSelectedDivision(e.target.value);
    };

    const handleSubmitBlock = async (e) => {
        e.preventDefault();

        try {
            // Check if selectedDivision and blockName are not empty before proceeding
            if (!selectedDivision || !blockName) {
                console.error('Please fill in all fields.');
                return;
            }

            const formData = new FormData();

            formData.append('selectedDivisionId', selectedDivision);
            formData.append('blockName', blockName);

            const response = await fetch(`${API_BASE_URL}block/store`, {
                method: 'POST',
                body: formData,
            });

            if (response.status == 200) {
                setShowModal(false);
                setShowSuccessModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    }

    const handleEditBlockModal = (blockId) => {
        setEditBlockId(blockId);
        setShowEditModal(true);
    };

    // Function to fetch zone data by ID
    const fetchBlockById = async (id) => {
        try {
            const response = await fetch(`${API_BASE_URL}block/edit/${id}`);
            if (response.ok) {
                const data = await response.json();
                console.log(data)
                const block = data.block;
                setEditBlockName(block.name);

                const divisions = data.divisions;
                const divisionIndex = divisions.findIndex((option) => {
                    console.log("Division Id:", block.division_id);
                    console.log("option.id:", option.id);
                    return String(option.id) === String(block.division_id);
                });

                if (divisionIndex !== -1) {
                    setSelectedDivisionId(divisions[divisionIndex].id);
                }
            }
        } catch (error) {
            console.error('Error fetching block:', error);
        }
    };

    useEffect(() => {
        if (editBlockId !== null) {
            fetchBlockById(editBlockId);
        }
    }, [editBlockId]);

    const handleEditedNameChange = (event) => {
        setEditBlockName(event.target.value);
    };

    const handleEditedDivisionChange = (e) => {
        setSelectedDivisionId(e.target.value);
    };

    const handleSubmitEditBlock = async (e) => {
        e.preventDefault();

        try {
            // Check if selectedDivision and blockName are not empty before proceeding
            if (!selectedDivisionId || !editBlockName) {
                console.error('Please fill in all fields.');
                return;
            }

            const formData = new FormData();

            formData.append('selectedDivisionId', selectedDivisionId);
            formData.append('blockName', editBlockName);

            const response = await fetch(`${API_BASE_URL}block/update/${editBlockId}`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                setSelectedDivisionId('');
                setShowEditModal(false);
                setShowSuccessModal(true);
            }
        } catch (error) {
            // Handle fetch errors
            console.error('Error updating zone:', error);
        }
    }

    const handleDeleteBlock = async (index) => {
        try {
            const response = await fetch(`${API_BASE_URL}block/destroy/${index}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status == 200) {
                setShowDeleteModal(false);
                setshowSuccessDeleteModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setDeleteIndex(null);
    };

    const handleConfirmDelete = (id) => {
        setDeleteIndex(id);
        setShowDeleteModal(true);
    };


    const closeSuccessModal = () => {
        setShowSuccessModal(false);
        window.location.reload();
    };

    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = blockData.slice(indexOfFirstItem, indexOfLastItem);

    const renderIndex = (index) => {
        return (currentPage - 1) * itemsPerPage + index + 1;
    };

    const totalPages = Math.ceil(blockData.length / itemsPerPage);
    const maxPagesToShow = 5;

    // Change page
    const paginate = (pageNumber) => {
        if (pageNumber < 1 || pageNumber > totalPages) {
            return;
        }
        setCurrentPage(pageNumber);
    };

    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (endPage - startPage + 1 < maxPagesToShow) {
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    const totalEntries = blockData.length;
    const startEntry = renderIndex(0);
    const endEntry = renderIndex(currentItems.length - 1);

    // Function to generate an array of page numbers to display
    const getPageNumbers = (start, end) => {
        return Array.from({ length: end - start + 1 }, (_, index) => start + index);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleEditModalClose = () => {
        setSelectedDivisionId('');
        setShowEditModal(false);
    };


    return (
        <LayoutPage>
            <div className="d-flex listTopBar top_bar p-3 bold_poppins justify-content-between">
                <h4 className="mt-3 pg_title">
                    LIST OF BLOCKS
                </h4>
            </div>
            <div className='children'>
                {canCreateBlock &&
                    <div className="addDisease d-flex justify-content-center mb-4" onClick={handleBlockModal}>
                        <div className="icon">
                            <FontAwesomeIcon className="icon" icon={faPlus} />
                        </div>
                        <div className="text ml-3">
                            Block
                        </div>
                    </div>
                }

                <div className='m-3 table-container table_responsive'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Division</th>
                                <th>Block Name</th>
                                {(canManageBlock || canEditBlock || canDeleteBlock) && <th className='text-center'>Action</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.length > 0 ? (
                                currentItems.map((block, index) => (
                                    <tr key={index}>
                                        <td>{renderIndex(index)}</td>
                                        <td>{block.division_name || '-'}</td>
                                        <td>{block.name || '-'}</td>
                                        {(canManageBlock || canEditBlock || canDeleteBlock) && <td className='text-center'>
                                            <div className="unwrap editdelete_icon">
                                                {canManageBlock &&
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Sub-blocks</Tooltip>} >
                                                        <FontAwesomeIcon
                                                            icon={faBuilding}
                                                            className="icon edit-icon"
                                                            onClick={() => navigate(`/block/sub-block/${block.id}`)}
                                                        /></OverlayTrigger>}
                                                {canEditBlock &&
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>} >
                                                        <FontAwesomeIcon
                                                            icon={faPencil}
                                                            className="icon edit-icon"
                                                            onClick={() => handleEditBlockModal(block.id)}
                                                        /></OverlayTrigger>}
                                                {canDeleteBlock &&
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>} >
                                                        <FontAwesomeIcon
                                                            icon={faTrash}
                                                            className="icon delete-icon"
                                                            onClick={() => handleConfirmDelete(block.id)}
                                                        /></OverlayTrigger>}
                                            </div>
                                        </td>}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="10" className="text-center">No data found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="d-flex flex-wrap-reverse">
                    <div className='col-md-6'>
                        <p className='pl-3' style={{ fontFamily: 'Poppins' }}>
                            Showing {startEntry} to {endEntry} of {totalEntries} entries
                        </p>
                    </div>

                    {/* Pagination component */}
                    <div className='col-md-6 pagination flex-wrap'>
                        <div>
                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                <button onClick={() => paginate(currentPage - 1)} className="page-link">
                                    Previous
                                </button>
                            </li>
                        </div>
                        <div className='d-flex flex-wrap'>
                            {getPageNumbers(startPage, endPage).map((page) => (
                                <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                                    <button onClick={() => paginate(page)} className="page-link">
                                        {page}
                                    </button>
                                </li>
                            ))}
                        </div>
                        <div >
                            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                <button onClick={() => paginate(currentPage + 1)} className="page-link">
                                    Next
                                </button>
                            </li>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal Create Block */}
            <Modal className='modal_responsive' show={showModal} onHide={handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Block</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Your form content */}
                    <Form onSubmit={handleSubmitBlock}>
                        <Form.Group>
                            <Form.Label className="label-left">Division</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    as="select"
                                    onChange={handleDivisionInputChange}
                                    name="division"
                                >
                                    <option value="">Select Division</option>
                                    {divisionData.map((option, optionIndex) => (
                                        <option key={optionIndex} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formBlockName" >
                            <Form.Label className="label-left">Block Name</Form.Label>
                            <Form.Control value={blockName} onChange={handleNameChange} type="text" placeholder="Enter block name" required />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-4">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Modal Update Zone */}
            <Modal className='modal_responsive' show={showEditModal} onHide={handleEditModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Block</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmitEditBlock}>
                        <Form.Group>
                            <Form.Label className="label-left">Division</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    as="select"
                                    onChange={handleEditedDivisionChange}
                                    name="division"
                                    value={selectedDivisionId || ""}
                                >
                                    <option value="">Select Division</option>
                                    {divisionData.map((option, optionIndex) => (
                                        <option key={optionIndex} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formBlockName" >
                            <Form.Label className="label-left">Block Name</Form.Label>
                            <Form.Control
                                value={editBlockName}
                                onChange={handleEditedNameChange}
                                type="text"
                                placeholder="Enter block name"
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-4">
                            Update
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Modal/Popup for Success Message */}
            <SuccessModal
                className="modal_responsive"
                show={showSuccessModal}
                onHide={closeSuccessModal}
                title="Success"
                modalBody={<p>Data successfully saved!</p>}
                buttonText="Close"
            />

            {/* Delete Modal */}
            <Modal className='modal_responsive' show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this block?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseDeleteModal}>
                        Cancel
                    </Button>
                    <Button className="delete-button" onClick={() => handleDeleteBlock(deleteIndex)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal/Popup for Confirmation Delete */}
            <SuccessModal
                className="modal_responsive"
                show={showSuccessDeleteModal}
                onHide={closeSuccessModal}
                title="Data Deleted"
                modalBody={<p>Data was successfully deleted!</p>}
                buttonText="OK"
            />

        </LayoutPage>
    )
}

export default BlockListingPage