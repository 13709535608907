import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View, StyleSheet, Image  } from '@react-pdf/renderer';
import { IMAGE_BASE_URL } from '../../constants/ApiConfig';

const styles = StyleSheet.create({
  page: {
    flex: 1,
    padding: 20,
    zIndex: 1,
  },
  section: {
    margin: 30,
    padding: 30,
    flexGrow: 1,
    border: '1 solid #fff',
    backgroundColor: '#fff',
    borderRadius: 10,
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
  },
  content: {
    fontSize: 16,
  },
  header: {
    paddingTop: 30,
    paddingBottom: 10,
    alignItems: 'center',
  },
  zoneText: {
    fontSize: 28,
    fontWeight: 800, 
    top: 25,
    marginLeft: 30,
  },
  centeredText: {
    fontSize: 18,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 4,
  },
  logoContainer: {
    position: 'absolute',
    top: -100,
    left: -10,
    zIndex: 2,
  },
  mainLogo: {
    width: 100,
    height: 100,
  },
});

const MyDocument = ({ blockName, plantation, qrCodeImageData }) => {
  console.log(plantation);
  return (
    <Document>
      <Page size={'A5'}>
        <View style={styles.header}>
          <Text style={styles.zoneText}>{`${plantation.division_name}`}</Text>
        </View>
        <View style={styles.page}>
            
            <View style={styles.section}>
              <View style={styles.logoContainer}>
                <Image src='/images/main_logo.png' style={styles.mainLogo} />
              </View>
              <Text style={styles.centeredText}>{`Block ${blockName} (${plantation.sub_block_name})`}</Text>
              {plantation.plant_no && (
                <Text style={styles.centeredText}>{`Plant No: ${plantation.plant_no}`} </Text>
              )}
              {qrCodeImageData && <Image src={qrCodeImageData} />}
              {plantation.clone_name && (
                <Text style={styles.centeredText}>{`${plantation.clone_name}`} </Text>
              )}
            </View>
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
