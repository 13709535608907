import LayoutPage from '../layouts/base';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencil, faQrcode, faRemove } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { API_BASE_URL, SABAHEBAT_DEV_URL } from '../../constants/ApiConfig';
import { Button, InputGroup, Modal, Table } from 'react-bootstrap';
import SuccessModal from '../components/success-modal';
import * as XLSX from 'xlsx';
import axios from 'axios';
import QRCodeModal from '../components/qrcode-modal';
import QRCodeNew from "qrcode";
import { useAuth } from '../../services/AuthProvider';

function PlantationByTypePage() {

    const { user, hasPermission } = useAuth();

    const navigate = useNavigate();

    const { type, subBlockId } = useParams();

    const canManagePlantation = hasPermission('manage-plantation');
    const canEditPlantation = hasPermission('edit-plantation');

    const itemsPerPage = 10;
    const [selectedDivision, setSelectedDivision] = useState('');
    const [selectedBlock, setSelectedBlock] = useState('');
    const [selectedSubBlock, setSelectedSubBlock] = useState('');
    const [plantationData, setPlantationData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (type !== '' && subBlockId !== 0) {
            fetch(`${API_BASE_URL}plantation/filter-plantation-by-clone/${type}/${subBlockId}?searchTerm=${searchTerm}`)
                .then((response) => response.json())
                .then((data) => {
                    const plantations = data.plantations;
                    console.log(plantations)
                    setPlantationData(plantations);

                    const filteredPlantations = filterData(plantations, searchTerm);
                    setFilteredData(filteredPlantations);

                    setSelectedDivision(data.division_name);
                    setSelectedBlock(data.block_name);
                    setSelectedSubBlock(data.sub_block_name);
                })
                .catch((error) => {
                    console.error('Error fetching plantation data:', error);
                });
        }
    }, [type, searchTerm]);

    // Function to filter data based on search term
    const filterData = (data, term) => {
        return data.filter((plantation) =>
            Object.values(plantation).some(
                (value) =>
                    value && value.toString().toLowerCase().includes(term.toLowerCase())
            )
        );
    };

    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const renderIndex = (index) => {
        return (currentPage - 1) * itemsPerPage + index + 1;
    };

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const maxPagesToShow = 5;

    // Change page
    const paginate = (pageNumber) => {
        if (pageNumber < 1 || pageNumber > totalPages) {
            return;
        }
        setCurrentPage(pageNumber);
    };

    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (endPage - startPage + 1 < maxPagesToShow) {
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    const totalEntries = plantationData.length;
    const startEntry = renderIndex(0);
    const endEntry = renderIndex(currentItems.length - 1);

    // Function to generate an array of page numbers to display
    const getPageNumbers = (start, end) => {
        return Array.from({ length: end - start + 1 }, (_, index) => start + index);
    };

    const handleExportToExcel = async (subBlockId) => {
        try {
            const response = await axios.get(`${API_BASE_URL}plantation/filter-plantation-by-clone/${type}/${subBlockId}`);
            const data = response.data;
            console.log(data);

            const plantations = data.plantations;

            if (plantations.length === 0) {
                console.error('No data to export');
                return;
            }

            const columnMapping = {
                plant_no: 'PLANT NO',
                division_name: 'DIVISION',
                block_name: 'BLOCK',
                sub_block_name: 'SUB-BLOCK',
                type: 'CLONE',
                year_planted: 'YEAR PLANTED',
                name: 'NAME',
                status: 'STATUS',
                latitude: 'LATITUDE',
                longitude: 'LONGITUDE',
                details: 'DETAILS',
                features: 'FEATURES',
                cares: 'CARES',
            };

            const selectedColumns = Object.keys(columnMapping);

            const filteredData = plantations.map((row) =>
                selectedColumns.reduce((acc, curr) => {
                    const newName = columnMapping[curr];
                    acc[newName] = row[curr];
                    return acc;
                }, {})
            );

            const worksheet = XLSX.utils.json_to_sheet(filteredData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

            const currentDate = new Date().toISOString().split('T')[0].replace(/-/g, '');
            const blockName = data.block_name;

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${currentDate}_${type}_${selectedDivision}_Block ${blockName}(${selectedSubBlock}).xlsx`;
            link.click();
        } catch (error) {
            console.error('Error exporting data:', error);
        }
    };

    const [modalShow, setModalShow] = useState(false);
    const [plantationIndex, setPlantationIndex] = useState(null);
    const [plantationQRImage, setPlantationQRImage] = useState(null);
    const [qrCodeImageData, setQRCodeImageData] = useState('');

    const handleGenerateQRCode = async (plantationId) => {
        try {
            const canvas = document.createElement('canvas');
            const url = `${SABAHEBAT_DEV_URL}/plantation/edit/${plantationId}?source=qrscan`;
            const redirectUrl = encodeURIComponent(url);
            const viewUrl = `${SABAHEBAT_DEV_URL}/public/plantation/view/${plantationId}?source=qrscan`;
            const redirectViewUrl = encodeURIComponent(viewUrl);
            const loginUrl = `${SABAHEBAT_DEV_URL}/?redirectUrl=${redirectUrl}&redirectViewUrl=${redirectViewUrl}`;
            QRCodeNew.toCanvas(canvas, loginUrl);
            const qrCodeDataURL = canvas.toDataURL('image/png');

            setQRCodeImageData(qrCodeDataURL);
        } catch (error) {
            console.error('Error generating or saving QR code:', error);
        }
    };

    const openQRModalWithId = (id, qrImage) => {
        setPlantationIndex(id);
        setPlantationQRImage(qrImage);
        setModalShow(true);
        handleGenerateQRCode(id);
    };

    const handleQRModalClose = () => {
        setModalShow(false);
        setPlantationIndex(null);
        setPlantationQRImage(null);
    };

    const clearSearch = () => {
        setSearchTerm('');
    };

    return (
        <div>
            <LayoutPage>
                <div className='children'>
                    <div className="d-flex listTopBar top_bar p-3 bold_poppins justify-content-between">
                        <h4 className="mt-3 pg_title">
                            LIST OF PLANTATIONS BY CLONE
                        </h4>
                        <div className="row mr-3">
                            <div className="col">
                                <Form.Control
                                    value={selectedDivision ? selectedDivision : "No division found"}
                                    readOnly
                                />
                            </div>
                            <div className="col">
                                <Form.Control
                                    value={selectedBlock ? `Block ${selectedBlock}` : "No block found"}
                                    readOnly
                                />
                            </div>
                            <div className="col">
                                <Form.Control
                                    value={selectedSubBlock ? selectedSubBlock : "No block found"}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-wrap listTopBar top_bar p-3 bold_poppins justify-content-between">
                        <div className="d-flex flex-wrap">
                            <div className="">
                                <InputGroup className="mr-3">
                                    <Form.Control
                                        placeholder="Search..."
                                        aria-label=""
                                        aria-describedby="basic-addon1"
                                        style={{ width: "200px" }}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        value={searchTerm}
                                    />
                                    {searchTerm && (
                                        <InputGroup.Text id="clear-search" onClick={clearSearch}>
                                            <FontAwesomeIcon icon={faRemove} className="icon edit-icon" />
                                        </InputGroup.Text>
                                    )}
                                </InputGroup>
                            </div>
                        </div>
                        {canManagePlantation &&
                            <div className='d-flex text-right'>
                                <button onClick={() => handleExportToExcel(subBlockId)} className='eksport_btn ml-2' target="_blank" rel="noopener noreferrer">
                                    Export
                                </button>
                            </div>
                        }
                    </div>

                    <div className='m-3 table-container table_responsive'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Clone</th>
                                    <th>Plant No</th>
                                    <th>Division</th>
                                    <th>Block</th>
                                    <th>Sub-Block</th>
                                    <th>Year Planted</th>
                                    <th>Latitude</th>
                                    <th>Longitude</th>
                                    {(canManagePlantation || canEditPlantation) && <th className='text-center'>Action</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.length > 0 ? (
                                    currentItems.map((plantation, index) => (
                                        <tr key={index}>
                                            <td>{renderIndex(index)}</td>
                                            <td style={{ backgroundColor: '#f9f9f9' }}>{plantation.type || '-'}</td>
                                            <td>{plantation.plant_no || '-'}</td>
                                            <td>{plantation.division.name || '-'}</td>
                                            <td>{plantation.block_name || '-'}</td>
                                            <td>{plantation.sub_block_name || '-'}</td>
                                            <td>{plantation.year_planted || '-'}</td>
                                            <td>{plantation.latitude || '-'}</td>
                                            <td>{plantation.longitude || '-'}</td>
                                            {(canManagePlantation || canEditPlantation) && <td className='text-center'>
                                                <div className="unwrap editdelete_icon">
                                                    {canManagePlantation && <FontAwesomeIcon icon={faQrcode} className="icon edit-icon" onClick={(e) => { e.stopPropagation(); openQRModalWithId(plantation.id, plantation.qr_image) }} />}
                                                    {canManagePlantation && <FontAwesomeIcon icon={faEye} className="icon edit-icon" onClick={() => navigate(`/plantation/view/${plantation.id}`)} />}
                                                    {canEditPlantation && <FontAwesomeIcon icon={faPencil} className="icon edit-icon" onClick={() => navigate(`/plantation/edit/${plantation.id}`)} />}
                                                </div>
                                            </td>}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="10" className="text-center">No data found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>
                    <div className="d-flex flex-wrap-reverse">
                        <div className='col-md-6'>
                            <p className='pl-3' style={{ fontFamily: 'Poppins' }}>
                                Showing {startEntry} to {endEntry} of {totalEntries} entries
                            </p>
                        </div>

                        {/* Pagination component */}
                        <div className='col-md-6 pagination flex-wrap'>
                            <div>
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <button onClick={() => paginate(currentPage - 1)} className="page-link">
                                        Previous
                                    </button>
                                </li>
                            </div>
                            <div className='d-flex flex-wrap'>
                                {getPageNumbers(startPage, endPage).map((page) => (
                                    <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                                        <button onClick={() => paginate(page)} className="page-link">
                                            {page}
                                        </button>
                                    </li>
                                ))}
                            </div>
                            <div >
                                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                    <button onClick={() => paginate(currentPage + 1)} className="page-link">
                                        Next
                                    </button>
                                </li>
                            </div>
                        </div>

                    </div>
                </div>

                <QRCodeModal
                    show={modalShow}
                    id={plantationIndex}
                    onHide={handleQRModalClose}
                    qrCodeImageData={qrCodeImageData}
                />

            </LayoutPage>
        </div>
    )
}

export default PlantationByTypePage