import React, { useState, useEffect, useRef } from "react";
import data_vector from "../../assets/combined.json";
import Select from "ol/interaction/Select.js";
import Map from "ol/Map.js";
import OSM from "ol/source/OSM.js";
import {
  ZoomToExtent,
  FullScreen,
  defaults as defaultControls,
} from "ol/control.js";

import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer.js";
import { Style, Icon } from "ol/style.js";
import VectorSource from "ol/source/Vector.js";
import GeoJSON from "ol/format/GeoJSON.js";
import View from "ol/View.js";
import "./map.css";
import Overlay from "ol/Overlay";
import "ol/ol.css";
import "../popup/popup.css";
import { hydrateRoot } from "react-dom/client";
import LayerGroup from "ol/layer/Group";

import PopupContent from "../popup/popup";
import LegendComponent from "./legend/legend";
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import { BaseLayerOptions, GroupLayerOptions } from "ol-layerswitcher";
// import { ol_control_LayerSwitcher } from "ol-ext/control/LayerSwitcher.js";
import LayerSwitcher from "ol-layerswitcher";

import QueryTool from "./map-controls/queryTool";
import MapPieChart from "./map-pie-chart/map-pie-chart";

const OpenLayersMap = () => {
  const mapRef = useRef(null);
  const [durianVectorLayer, setDurianVectorLayer] = useState(null);
  const [mapObj, setMapObj] = useState(null);
  const defaultExtentCoord = [115.81369, 5.454983, 115.8349, 5.47658];

  const styles = {};
  // color for Block
  const blockStyle = {
    A: "#59C7FF",
    B: "#FF8C00",
    C: "#D6338B",
    D: "#9E9DFF",
    E: "#FFCE56",
    F: "#4CAF50",
    G: "#00CED1",
    H: "#F44336",
    I: "#F08080",
  };

  // svg icon for display
  const svgIcon = (color) => {
    return `  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" fill="${color}" height="24">
                <path fill="none" d="M0 0h24v24H0V0z"/>
                <path d="M20.99 12c0-.9-.67-2.11-1.52-2.6 1.17-.72 1.92-1.97 1.86-3.4-.05-1.53-.96-2.82-2.34-3.46A3.474 3.474 0 0 0 15.5 2c-1.48 0-2.73.91-3.25 2.21A4.491 4.491 0 0 0 9 3.2c-.66 0-1.28.15-1.84.4-1.38.64-2.29 1.93-2.34 3.46-.06 1.43.69 2.68 1.86 3.4-.85.49-1.52 1.7-1.52 2.6 0 1.66 1.34 3 3 3h10c1.65 0 3-1.34 3-3zm-4.01-5c-.33-.48-.77-.86-1.3-1.15A3.485 3.485 0 0 0 12 4.5c-.74 0-1.42.23-1.99.61-.53.29-.97.67-1.3 1.15-.33.48-.52 1.06-.52 1.65 0 .41.13.8.37 1.13.16.23.43.41.73.52.04.02.08.02.11.02.06 0 .12-.02.18-.04.23-.09.42-.26.57-.47.35-.51.8-.97 1.33-1.3.28-.16.6-.27.94-.34.05-.01.1-.01.15-.01.18 0 .36.03.53.08.22.08.42.22.58.39.15.18.26.4.32.64.07.25.07.51-.01.77zM15.5 20h-7c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5c.28 0 .55.08.79.22.26.14.56.22.91.22s.65-.08.91-.22c.24-.13.51-.22.79-.22.83 0 1.5.67 1.5 1.5S16.33 20 15.5 20z"/>
              </svg>
  `;
  };

  // Create a Style object and apply the Icon
  for (let key of Object.keys(blockStyle)) {
    styles[key] = new Style({
      image: new Icon({
        src: `data:image/svg+xml;utf8,${encodeURIComponent(
          svgIcon(blockStyle[key])
        )}`,
        // anchor: [0.5, 1], // Set the anchor point if necessary
      }),
    });
  }

  const styleFunction = function (feature) {
    return styles[feature.getProperties()["BLOK"]];
  };

  const popupContainer = document.createElement("div");
  popupContainer.className = "ol-popup";

  // const content = document.createElement("p");
  // popupContainer.appendChild(content);

  const popup = new Overlay({
    element: popupContainer,
    autoPan: true,
    autoPanAnimation: {
      duration: 250,
    },
  });
  let mapClicked = null;
  const handleFeatureClick = (event) => {
    if (event.selected.length) {
      const feature = event.selected[0];
      hydrateRoot(
        popupContainer,
        <PopupContent
          feature={feature}
          map={mapClicked.map}
          overlay={popup}
          selected={selectInteraction}
        />
      );
    } else {
      popup.setPosition(undefined);
      selectInteraction.getFeatures().clear();
    }
  };

  const defaultExtent = new ZoomToExtent({
    extent: defaultExtentCoord,
  });

  // Add click interaction to trigger popup
  const selectInteraction = new Select({
    hitTolerance: 5, // Adjust click tolerance as needed
  });
  // let durianLayer;
  selectInteraction.on("select", handleFeatureClick);
  useEffect(() => {
    const vectorSource = new VectorSource({
      features: new GeoJSON().readFeatures(data_vector),
    });
    const durianLayer = new VectorLayer({
      title: "Durian Tree",
      visible: true,
      source: vectorSource,
      style: styleFunction,
    });
    setDurianVectorLayer({ layer: durianLayer });

    const overlayLayers = new LayerGroup({
      title: "Overlay",
      // type: "base",
      layers: [durianLayer],
    });
    const baseMaps = new LayerGroup({
      title: "Basemap",
      type: "base",
      layers: [
        new TileLayer({
          title: "OSM",
          source: new OSM(),
        }),
      ],
    });

    // Initialize the map on mount
    const map = new Map({
      target: mapRef.current,
      controls: defaultControls().extend([defaultExtent]),
      layers: [baseMaps, overlayLayers],
      view: new View({
        center: [115.815999, 5.474517],
        zoom: 14,
        projection: "EPSG:4326",
      }),
    });
    map.addOverlay(popup);
    setMapObj(map);
    const layerSwitcher = new LayerSwitcher({
      reverse: true,
      groupSelectStyle: "none",
      startActive: true,
      tipLabel: "Legend",
      hidden: false,
      icon: false,
    });

    const div = document.createElement("div");
    const legendDiv = document.createElement("div");
    legendDiv.className = "legend-container";
    // legendDiv.appendChild(legendComponent);
    div.appendChild(legendDiv);

    map.addControl(layerSwitcher);
    map.addInteraction(selectInteraction);

    // div.appendChild(layerSwitcher.renderPanel(map));
    map.on("click", (event) => {
      mapClicked = event;
      const trees = map.getFeaturesAtPixel(event.pixel, {
        layerFilter: (layer) => layer === durianLayer,
      });
      const coordinate = event.coordinate;
      if (trees.length) popup.setPosition(coordinate);
    });
    // Optionally add event listeners or perform other map actions
    return () => {
      map.setTarget(null);
    };
  }, []); // Update map on center/zoom changes

  return (
    <>
      <div ref={mapRef} id="map" className="map-container" />
      <QueryTool className="z-1" vectorLayer={durianVectorLayer} map={mapObj} />
      <MapPieChart vectorLayer={durianVectorLayer} map={mapObj} />
      <LegendComponent className="z-0" styles={styles} map={mapObj} />
    </>
  );
};

export default OpenLayersMap;
