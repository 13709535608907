import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Sidebar from '../sidebars/sidebar';
import Dropdown from 'react-bootstrap/Dropdown';
import { API_BASE_URL, IMAGE_BASE_URL } from '../../constants/ApiConfig';
import { logout } from '../../services/auth';
import { useAuth } from '../../services/AuthProvider';

const LayoutPage = ({ children }) => {

    const { user, hasPermission } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [phaseData, setPhaseData] = useState([]);
    const [cloneData, setCloneData] = useState([]);

    const USER_ID_KEY = 'user_id';
    const userId = localStorage.getItem(USER_ID_KEY);
    
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const mainStyle = {
        marginLeft: sidebarOpen ? '250px' : '0',
        height: '90vh',
        overflowX: 'auto',
    };

    useEffect(() => {
        // Update sidebar state based on screen size when component mounts
        const handleResize = () => {
            if (window.innerWidth <= 767) {
                setSidebarOpen(false); // Set to true for phone size
            } else {
                setSidebarOpen(true); // Set to false for tablet and desktop sizes
            }
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Call the handleResize function once to set the initial state
        handleResize();

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [userData, setUserData] = useState([]);

    // Fetch user data
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}user/getUser/${userId}`);
                if (response.ok) {
                    const data = await response.json();
                    console.log(data)

                    setUserData(data.user);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, [userId]);

    const handleLogout = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_BASE_URL}logout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId }),
            });

            if (response.ok) {
                logout();

                const redirectUrl = localStorage.getItem('redirectUrl');
                if(redirectUrl) {
                    localStorage.removeItem('redirectUrl');
                }
                navigate('/');
            }
        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    };

    const childrenWithProps = React.Children.map(children, (child) => {
        return React.cloneElement(child, { style: { marginLeft: sidebarOpen ? '0' : '80px' } });
    });

    const breadcrumbs = [
        // { path: '/dashboard', label: 'Dashboard' },
        // { path: '/division', label: 'Division' },
        // Add more breadcrumb paths and labels as needed
    ];

    const currentPath = location.pathname;
    const $id = String(location.pathname.split('/').pop());
    const breadcrumbMap = {
        '/dashboard': ['Dashboard'],
        '/division': ['Division', 'List of Division'],
        '/block': ['Blocks', 'List of Blocks'],
        '/block/sub-block/{$id}': ['Blocks', 'List of Blocks', 'Sub-Block'],
        '/division': ['Division', 'List of Division'],
        '/plantation': ['Plantation', 'List of Plantation'],
        '/plantation/QRgenerator': ['QR Generator', 'QR Generator'],
        '/disease': ['Disease', 'Disease List'],
        '/program': ['Programs', 'Programs'],
        '/role': ['Roles', 'Role List'],
        '/user': ['Users', 'User List'],
        '/report/division': ['Reports', 'Report By Division'],
        '/report/clone': ['Reports', 'Report By Clone'],
        '/report/disease': ['Reports ', 'Disease'],
        '/report/plantation': ['Reports ', 'Report By Plantation'],
        '/report/fruit': ['Reports ', 'Report By Fruit'],
        '/report/phase': ['Reports ', 'Phase'],
        '/report/division/block/{$id}': ['Reports', 'Report By Block'],
        '/report/disease/{$id}': ['Reports', 'Report By Disease'],
        '/report/fruit/{$id}': ['Reports', 'Report By Fruit'],
        '/report/phase/{$id}': ['Reports', 'Report By Phase'],
    };

    const phaseId = $id;
    useEffect(() => {
        const fetchPhaseData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}phase/edit/${phaseId}`);
                if (response.ok) {
                    const data = await response.json(); console.log(data);
                    setPhaseData(data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchPhaseData();
    }, [phaseId]);

    const cloneId = $id;
    useEffect(() => {
        const fetchCloneData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}clone/edit/${cloneId}`);
                if (response.ok) {
                    const data = await response.json(); console.log(data);
                    setCloneData(data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchCloneData();
    }, [cloneId]);
    
    if (location.pathname == '/dashboard') {
        breadcrumbs.push({ path: '/dashboard', label: 'Dashboard' });
    }
    else if (breadcrumbMap[currentPath]) {
        const labels = breadcrumbMap[currentPath];
        labels.forEach((label, index) => {
            if (index === 0) {
                breadcrumbs.push({ path: currentPath, label });
            } else {
                breadcrumbs.push({ path: currentPath, label: `${label}` });
            }
        });
    } else if (currentPath.startsWith('/block/sub-block/') && !isNaN($id)) {
        breadcrumbs.push({ path: '', label: 'Blocks' });
        breadcrumbs.push({ path: '/block', label: 'List of Blocks' });
        breadcrumbs.push({ path: currentPath, label: 'Sub-Blocks ' });
    }
     else if (currentPath.startsWith('/report/division/block/sub-block') && !isNaN($id)) {
        breadcrumbs.push({ path: '', label: 'Reports' });
        breadcrumbs.push({ path: '/report/division', label: 'Division' });
        breadcrumbs.push({ path: '', label: 'Block' });
        breadcrumbs.push({ path: currentPath, label: 'Sub-Blocks' });
    }else if (currentPath.startsWith('/report/division/block') && !isNaN($id)) {
        breadcrumbs.push({ path: '', label: 'Reports' });
        breadcrumbs.push({ path: '/report/division', label: 'Division' });
        breadcrumbs.push({ path: currentPath, label: 'Block' });
    }else if (currentPath.startsWith('/report/disease') && !isNaN($id)) {
        breadcrumbs.push({ path: '', label: 'Reports' });
        breadcrumbs.push({ path: '/report/disease', label: 'List of Disease' });
        breadcrumbs.push({ path: currentPath, label: 'Disease' });
    }else if (currentPath.startsWith('/report/fruit') && ($id)) {
        breadcrumbs.push({ path: '', label: 'Reports' });
        breadcrumbs.push({ path: '/report/fruit', label: 'Fruit' });
        breadcrumbs.push({ path: currentPath, label: cloneData.name });
    } else if (currentPath.startsWith('/report/phase') && ($id) && phaseData) {
        breadcrumbs.push({ path: '', label: 'Reports' });
        breadcrumbs.push({ path: '/report/phase', label: 'Phase' });
        breadcrumbs.push({ path: currentPath, label: phaseData.name });
    }

    return (
        <div className='base_fixed'>
            <div className=" mt-1  banner">
                <div className='d-flex flex-wrap col-md-12 top_bar justify-content-between mb-2'>
                    <div className='d-flex top_bar'>
                        <div className=''> <img
                            src="/images/main_logo.png"
                            className="img-fluid rounded-start main_logo_layout"
                            alt="..."
                        /></div>
                        <div className=''> <p className="big_title">SABAHEBAT Durian Plantation</p>

                        </div>
                    </div>


                    <div className=''>
                        <Dropdown className='custom-dropdown'>
                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                <div className='d-flex profile_top_bar'>
                                    <div>
                                        <img
                                            src={userData.image ? `${IMAGE_BASE_URL}${userData.image}` : "/images/badz0.png"}
                                            className="img-fluid profile_pic"
                                            alt="Profile picture"
                                        />
                                    </div>
                                    <div className='pro_bar text-left'>
                                        <p className="admin-title">{ userData.role_name}</p>
                                        <p className="admin-name bold_poppins">{userData.name}</p>
                                    </div>
                                </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="custom-dropdown-menu">
                                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>



                </div>
                <div>

                </div>

            </div>
            {/* <button className="w3-button w3-teal" onClick={toggleSidebar}>&#9776;</button> */}
            {/* <Navbar bg="dark" data-bs-theme="dark" className=''>
                <div className='justify-content-start navbar_layout'>
                    <Navbar.Brand href="#home" className='text-white'>BORANG TAMAN AGROTEKNOLOGI</Navbar.Brand>
                    <Navbar.Brand href="#home" className='text-white'>QR Generator</Navbar.Brand>

                </div>
            </Navbar> */}

            {/* <div className='bg-dark justify-content-between  d-flex flex-wrap'>
                <div className='d-flex flex-wrap navbar_layout '>
                    <button className="w3-button w3-teal" onClick={toggleSidebar}>&#9776;</button>

                </div>

                <div className=' d-flex justify-content-center align-items-center '>

                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            Selamat Datang, Admin
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="custom-dropdown-menu">
                            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

            </div> */}
            <div className='d-flex justify-content-between  resp_bar' id='resp_bar'>
                <button className="w3-button w3-teal2" onClick={toggleSidebar}>&#9776;</button>
                <div className='resp_dropdown'>
                    <Dropdown className='custom-dropdown'>
                        <Dropdown.Toggle variant="" id="dropdown-basic">
                            <div className='d-flex profile_top_bar'>
                                <div>
                                    <img
                                        src={userData.image ? `${IMAGE_BASE_URL}${userData.image}` : "/images/badz0.png"}
                                        className="img-fluid profile_pic_resp"
                                        alt="Profile picture"
                                    />
                                </div>
                                <div className='pro_bar text-left'>
                                    <p className="admin-title">{ userData.role_name}</p>
                                    <p className="admin-name bold_poppins">{userData.name}</p>
                                </div>
                            </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="custom-dropdown-menu">
                            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>

            <div className='qrPage'>


                <Sidebar sidebarOpen={sidebarOpen} closeSidebar={toggleSidebar} />

                <div id="main" className='' style={mainStyle}>
                <Breadcrumb className='breadcrumbs'>
                {breadcrumbs.map((crumb, index) => (
                    <Breadcrumb.Item key={index} active={index === breadcrumbs.length - 1}>
                        {location.pathname === crumb.path ? (
                            crumb.label
                        ) : (
                            <Link to={crumb.path}>{crumb.label}</Link>
                        )}
                    </Breadcrumb.Item>
                ))}
            </Breadcrumb>
                    <button className="w3-button w3-teal" onClick={toggleSidebar}>&#9776;</button>
                    {childrenWithProps}
                </div>
            </div>
        </div>
    );
};

export default LayoutPage;
