import { useEffect, useState } from 'react';
import LayoutPage from '../layouts/base';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useNavigate, useParams } from 'react-router-dom';
import { API_BASE_URL, IMAGE_BASE_URL, SABAHEBAT_DEV_URL } from '../../constants/ApiConfig';
import { Table } from 'react-bootstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function ViewPlantationPage() {

    const navigate = useNavigate();

    const redirectUrl = localStorage.getItem('redirectUrl');

    const { plantationId } = useParams();
    const [blockData, setBlockData] = useState([]);
    const [subBlockData, setSubBlockData] = useState([]);
    const [divisionData, setDivisionData] = useState([]);
    const [plantationData, setPlantationData] = useState([]);
    const [plantationImages, setPlantationImages] = useState([]);
    const [statisticsData, setStatisticsData] = useState([]);
    const [diseasesData, setDiseasesData] = useState([]);
    const [remarksData, setRemarksData] = useState([]);
    const [phasesData, setPhasesData] = useState([]);

    var item_name = plantationData.name;

    function nameFormatted(string) {
        return string.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }


    useEffect(() => {
        const fetchPlantationData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}plantation/edit/${plantationId}`);
                if (response.ok) {
                    const data = await response.json();

                    setSubBlockData(data.subBlock);
                    setBlockData(data.block);
                    setDivisionData(data.division);

                    setPlantationData(data.plantation);
                    if (data.plantation && data.plantation.plant_images) {
                        setPlantationImages(data.plantation.plant_images);
                    }
                    setStatisticsData(data.plant_statistics);
                    setDiseasesData(data.plant_diseases);
                    setRemarksData(data.plant_remarks);
                    setPhasesData(data.plant_phases);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchPlantationData();
    }, [plantationId]);

    // Pagination
    const Pagination = ({ data, itemsPerPage, renderItem, headers }) => {
        const [currentPage, setCurrentPage] = useState(1);

        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

        const renderIndex = (index) => {
            return (currentPage - 1) * itemsPerPage + index + 1;
        };

        const totalItems = Math.ceil(data.length / itemsPerPage);
        const maxPagesToShow = 5;

        const paginate = (pageNumber) => {
            if (pageNumber < 1 || pageNumber > totalItems) {
                return;
            }
            setCurrentPage(pageNumber);
        };

        let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
        let endPage = Math.min(totalItems, startPage + maxPagesToShow - 1);

        if (endPage - startPage + 1 < maxPagesToShow) {
            startPage = Math.max(1, endPage - maxPagesToShow + 1);
        }

        const getPageNumbers = (start, end) => {
            return Array.from({ length: end - start + 1 }, (_, index) => start + index);
        };

        return (
            <div class="table-container table_responsive">
                <table className="table">
                    <thead>
                        <tr>
                            {headers.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.length > 0 ? (
                            currentItems.map((item, index) => (
                                <tr key={index}>
                                    <td>{renderIndex(index)}</td>
                                    {renderItem(item)}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="10" className="text-center">No data found</td>
                            </tr>
                        )}
                    </tbody>
                </table>

                {/* Pagination component */}
                <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button onClick={() => paginate(currentPage - 1)} className="page-link">
                            Previous
                        </button>
                    </li>
                    {getPageNumbers(startPage, endPage).map((page) => (
                        <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                            <button onClick={() => paginate(page)} className="page-link">
                                {page}
                            </button>
                        </li>
                    ))}
                    <li className={`page-item ${currentPage === totalItems ? 'disabled' : ''}`}>
                        <button onClick={() => paginate(currentPage + 1)} className="page-link">
                            Next
                        </button>
                    </li>
                </ul>
            </div>
        );
    };

    const itemsPerPage = 5;

    const statisticsHeaders = ['No', 'Date', 'Quantity'];
    const diseasesHeaders = ['No', 'Date', 'Diseases Name'];
    const remarksHeaders = ['No', 'Date', 'Remarks'];
    const phasesHeaders = ['No', 'Date', 'Phase'];

    const renderStatisticsItem = (item) => {
        return (
            <>
                <td>{item.date_recorded}</td>
                <td>{item.quantity}</td>
            </>
        );
    };

    const renderDiseasesItem = (item) => {
        return (
            <>
                <td>{item.date_recorded}</td>
                <td>{item.disease_name}</td>
            </>
        );
    };

    const renderRemarksItem = (item) => {
        return (
            <>
                <td>{item.date_recorded}</td>
                <td>{item.name}</td>
            </>
        );
    };

    const renderPhasesItem = (item) => {
        return (
            <>
                <td>{item.date_recorded}</td>
                <td>{item.phase_name}</td>
            </>
        );
    };

    return (
        <div>
            <LayoutPage>
                <div className='children'>
                    <div className='container'>
                        <div className="row my-3">
                            <div className="col-md-1">
                                <Form.Label className="label_control" htmlFor="basic-url">Division:</Form.Label>
                            </div>
                            <div className="col-md-3">
                                <InputGroup className="mb-3 regular_poppins">
                                    <Form.Control id="basic-url" aria-describedby="basic-addon3" value={divisionData.name} disabled />
                                </InputGroup>
                            </div>
                            <div className="col-md-1 p-0">
                                <Form.Label className="label_control" htmlFor="basic-url">Block:</Form.Label>
                            </div>
                            <div className="col-md-3">
                                <InputGroup className="mb-3 regular_poppins">
                                    <Form.Control id="basic-url" aria-describedby="basic-addon3" value={blockData.name} disabled />
                                </InputGroup>
                            </div>
                            <div className="col-md-1">
                                <Form.Label className="label_control" htmlFor="basic-url">Sub-Block:</Form.Label>
                            </div>
                            <div className="col-md-3">
                                <InputGroup className="mb-3 regular_poppins">
                                    <Form.Control id="basic-url" aria-describedby="basic-addon3" value={plantationData.sub_block_name} disabled />
                                </InputGroup>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-3 text-center slider_img'>
                                <Slider
                                    dots={true}
                                    arrows={true}
                                    prevArrow={<button type="button" className="slick-prev">Previous</button>}
                                    nextArrow={<button type="button" className="slick-next">Next</button>}
                                >
                                    {plantationImages.map((image, index) => (
                                        <div key={index} className='preview-container '>
                                            <a href={`${IMAGE_BASE_URL}${image.name}`} target="_blank" rel="noopener noreferrer">
                                                <img
                                                    src={`${IMAGE_BASE_URL}${image.name}`}
                                                    className="img-fluid img_view2 mb-2"
                                                    alt={`Preview-${index}`}
                                                />
                                            </a>
                                        </div>
                                    ))}
                                </Slider>
                            </div>

                            <div className='col-lg-9 p-0'>
                                <div>
                                    <label className='label_view bold_poppins'>Plant No:</label>
                                    <p className='data_view regular_poppins'>{plantationData.plant_no ? plantationData.plant_no : '-'}</p>
                                </div>
                                <div>
                                    <label className='label_view bold_poppins'>Clone:</label>
                                    <p className='data_view regular_poppins'>{plantationData.type ? plantationData.type : '-'}</p>
                                </div>
                                <div>
                                    <label className='label_view bold_poppins'>Year Planted:</label>
                                    <p className='regular_poppins'>{plantationData.year_planted ? plantationData.year_planted : '-'}</p>
                                </div>
                                <div>
                                    <label className='label_view bold_poppins'>Longitude:</label>
                                    <p className='regular_poppins text-justify'>{plantationData.longitude ? plantationData.longitude : '-'}</p>
                                </div>
                                <div>
                                    <label className='label_view bold_poppins'>Latitude:</label>
                                    <p className='regular_poppins text-justify'>{plantationData.latitude ? plantationData.latitude : '-'}</p>
                                </div>
                                {/* <div>
                                    <label className='label_view bold_poppins'>Features:</label>
                                    <p className='regular_poppins text-justify'>{plantationData.features ? plantationData.features : '-'}</p>
                                </div>
                                <div>
                                    <label className='label_view bold_poppins'>Care:</label>
                                    <p className='regular_poppins text-justify'>{plantationData.cares ? plantationData.cares : '-'}</p>
                                </div>
                                <div>
                                    <label className='label_view bold_poppins'>Status:</label>
                                    <p className='regular_poppins text-justify'>{plantationData.status ? plantationData.status : '-'}</p>
                                </div> */}

                                <div className="row mt-4 mb-2">
                                    <div className="col-md-12 d-flex justify-content-between">
                                        <label className='label_view bold_poppins'>Fruit Statistics:</label>
                                    </div>
                                    <div className="col-md-12 mt-2 ml-auto">
                                        <Pagination
                                            data={statisticsData}
                                            itemsPerPage={itemsPerPage}
                                            renderItem={renderStatisticsItem}
                                            headers={statisticsHeaders}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md-12 d-flex justify-content-between">
                                        <label className='label_view bold_poppins'>Diseases:</label>
                                    </div>
                                    <div className="col-md-12 mt-2 ml-auto">
                                        <Pagination
                                            data={diseasesData}
                                            itemsPerPage={itemsPerPage}
                                            renderItem={renderDiseasesItem}
                                            headers={diseasesHeaders}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md-12 d-flex justify-content-between">
                                        <label className='label_view bold_poppins'>Phases:</label>
                                    </div>
                                    <div className="col-md-12 mt-2 ml-auto">
                                        <Pagination
                                            data={phasesData}
                                            itemsPerPage={itemsPerPage}
                                            renderItem={renderPhasesItem}
                                            headers={phasesHeaders}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md-12 d-flex justify-content-between">
                                        <label className='label_view bold_poppins'>Remarks:</label>
                                    </div>
                                    <div className="col-md-12 mt-2 ml-auto">
                                        <Pagination
                                            data={remarksData}
                                            itemsPerPage={itemsPerPage}
                                            renderItem={renderRemarksItem}
                                            headers={remarksHeaders}
                                        />
                                    </div>
                                </div>

                                <div className='mt-3 text-center' style={{ paddingBottom: '30%' }}>
                                    {!redirectUrl && (
                                        <button className='btn formBatalBtn' onClick={() => navigate(`/plantation`)}>Back</button>
                                    )}
                                    {redirectUrl && (
                                        <button className='btn formBatalBtn' onClick={() => navigate(`/plantation/edit/${plantationId}?source=qrscan`)}>Back</button>
                                    )}

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </LayoutPage>
        </div>
    )
}

export default ViewPlantationPage